import get from "lodash/get";

import { SocketService } from "../services/SocketService";
import { TypeNotification } from "../utils/types";
import { actions as appActions } from "./app";
import { actions as carpoolActions } from "./carpool";

export const createWebsocketMiddleware = () => {
  return (store: any) => {
    const socket = SocketService.getInstance().socket;

    socket.on("carpool_updated", (event: any) => {
      store.dispatch(carpoolActions.wsCarpoolUpdated(get(event, "data", {})));
    });

    socket.on("carpool_request_join", (event: any) => {
      store.dispatch(carpoolActions.wsCarpoolRequestJoin(get(event, "data", {})));
    });

    socket.on("carpool_request_accept", (event: any) => {
      store.dispatch(carpoolActions.wsCarpoolRequestAccept(get(event, "data", {})));
    });

    socket.on("carpool_reject_join", (event: any) => {
      store.dispatch(carpoolActions.wsCarpoolRejectJoin(get(event, "data", {})));
    });

    socket.on("carpool_terminated", (event: any) => {
      store.dispatch(carpoolActions.wsCarpoolTerminated(get(event, "data", {})));
    });

    socket.on("carpool_removed_user", (event: any) => {
      store.dispatch(carpoolActions.wsCarpoolRemovedUser(get(event, "data", {})));
    });

    socket.on("new-message", (event: any) => {
      store.dispatch(carpoolActions.wsCarpoolNewMessage(event));

      // Enqueue notification to display on carpool item
      store.dispatch(
        appActions.enqueueNotification({
          type: TypeNotification.CarpoolChat,
          message: "New message",
          read: false,
        }),
      );
    });

    socket.on("new-notification", (event: any) => {
      store.dispatch(appActions.enqueueNotification(event));
    });

    return (next: any) => (action: any) => {
      return next(action);
    };
  };
};
