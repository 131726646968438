import React from "react";

import { ReactComponent as LanguageIcon } from "../../../assets/svg/global-icon.svg";

import { Dropdown } from "@qryde/components-ui";
import { useTranslation, Trans, withTranslation } from "react-i18next";

const LanguageMenu: React.FC = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (lng: string) => () => i18n.changeLanguage(lng);

  return (
    <Dropdown icon={LanguageIcon} avatarBg={false} avatarColor="white">
      <button
        className="block text-gray-2 mb-4 cursor-pointer hover:text-primary-green"
        onClick={handleChangeLanguage("en")}
      >
        <Trans i18nKey="component.language_menu.english">English</Trans>
      </button>
      <button
        className="block text-gray-2 mb-4 cursor-pointer hover:text-primary-green"
        onClick={handleChangeLanguage("es")}
      >
        <Trans i18nKey="component.language_menu.spanish">Spanish</Trans>
      </button>
    </Dropdown>
  );
};

LanguageMenu.displayName = "LanguageMenu";

export default withTranslation()(LanguageMenu);
