import storage from "../utils/storage";
import { CarpoolService } from "./CarpoolService";
import { CommunityService } from "./CommunityService";
import { TeleworkService } from "./TeleworkService";
import { TransportationModeService } from "./TransportationModeService";

export class ApiService {
  public static carpool = CarpoolService;
  public static community = CommunityService;
  public static teleworks = TeleworkService;
  public static transportationMode = TransportationModeService;

  public async register(user: any) {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async login(userData: any) {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      const { status, ...rest } = await res.json();

      if (status !== 401) {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async logout() {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/users/logout`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const { status, ...rest } = await res.json();

      if (status !== 401) {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async loginFacebook(userData: any) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users/login/facebook`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        },
      );

      const { status, ...rest } = await res.json();

      if (status !== 401) {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async loginGoogle(userData: any) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users/login/google`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        },
      );

      const { status, ...rest } = await res.json();

      if (status !== 401) {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async loginSuccess() {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users/login/success`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        },
      );

      const { status, ...rest } = await res.json();

      if (status !== 401) {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async confirmEmail(token: string) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users/confirm_email`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async getUser() {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateUser(user: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(user),
      });

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async getNotifications() {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/notifications`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateNotifications(data: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/notifications`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async getIncentives(user: string) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users/carpool_incentive?userId=${user}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async validatePin(data: any) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users/community/validate_pin`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public async setPassword(data: any) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users/reset_password`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }
}
