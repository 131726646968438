import { INotification, TypeNotification } from "../../utils/types";

import { selectorScoper } from "../../utils/redux";
import { IState } from "./types";

export function buildSelectors(path: string[]) {
  const scopeSelector = selectorScoper<IState>(path);

  const isLoading = (state: any) => scopeSelector(state).isLoading;
  const user = (state: any) => scopeSelector(state).user;
  const snackbars = (state: any) => scopeSelector(state).snackbars;
  const isAuthenticated = (state: any) => scopeSelector(state).isAuthenticated;
  const login = (state: any) => scopeSelector(state).login;
  const register = (state: any) => scopeSelector(state).register;
  const notificationsApp = (state: any) =>
    scopeSelector(state).notifications.filter(
      (notification: INotification) => notification.type !== TypeNotification.CarpoolChat,
    );
  const notificationsChat = (state: any) =>
    scopeSelector(state).notifications.filter(
      (notification: INotification) =>
        notification.type === TypeNotification.CarpoolChat && !notification.read,
    );
  const incentives = (state: any) => scopeSelector(state).incentives;
  const showCovidAlert = (state: any) => scopeSelector(state).showCovidAlert;

  return {
    isLoading,
    user,
    snackbars,
    isAuthenticated,
    login,
    register,
    notificationsApp,
    notificationsChat,
    incentives,
    showCovidAlert,
  };
}
