import storage from "../utils/storage";

export class TeleworkService {
  static async getAll() {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(`${process.env.REACT_APP_API_URL}/teleworks`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async save(data: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(`${process.env.REACT_APP_API_URL}/teleworks`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }
  public static async delete(id: string) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(`${process.env.REACT_APP_API_URL}/telework`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: String(id) }),
      });

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }
}
