import get from "lodash/get";

import { getHomeAddress, getWorkAddress } from "./address";
import { ICarpool } from './types';

export interface IRouteInfo {
  so_id: string;
  pu_stop: number;
  do_stop: number;
  distance: number;
}

export const getRouteInfo = async (
  standingOrderWalker: ICarpool,
  standingOrders: ICarpool[],
) => {
  const DirectionsService = new google.maps.DirectionsService();

  // 1- Get route info from google maps direction
  const { result: routeInfo } = await new Promise<any>((resolve, reject) => {
    DirectionsService.route(
      {
        origin: getHomeAddress(standingOrderWalker),
        destination: getWorkAddress(standingOrderWalker),
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        optimizeWaypoints: true,
        waypoints: standingOrders.flatMap((standingOrder: any) => [
          {
            location: getHomeAddress(standingOrder),
          },
          { location: getWorkAddress(standingOrder) },
        ]),
      },
      (result, status) => {
        if (status !== google.maps.DirectionsStatus.OK) {
          reject(result);
        }

        resolve({ status, result });
      },
    );
  });

  // 2- Get waypoints order from google map direction info
  const waypoint_order: number[] = get(
    routeInfo,
    "routes[0].waypoint_order",
    [],
  );

  // 3- Get legs route info from the first routes
  const legs: google.maps.DirectionsLeg[] = get(routeInfo, "routes[0].legs");

  let total_miles: number = 0;

  // 4- Create the first route info (carpool walker route info)
  const owner_route_info: IRouteInfo = {
    so_id: standingOrderWalker.so_id,
    pu_stop: 1,
    do_stop: standingOrders.length ? (standingOrders.length + 1) * 2 : 2,
    distance: get(legs, "[0].distance.value", 0) / 1609,
  };

  // 5- Initialize array from all carpool standing orders from a group_id
  const members_route_info: IRouteInfo[] = standingOrders.map((so: any) => ({
    so_id: so.so_id,
    pu_stop: 0,
    do_stop: 0,
    distance: 0,
  }));

  // 6- Iterate waypoint_orders to add the member route info point order
  for (let i = 0; i < waypoint_order.length; i++) {
    let key: number;
    // 6.1- If the waypoint order is even (We know that waypoint correspond to pickup address)
    if (waypoint_order[i] % 2 === 0) {
      // 6.1.1 - If is 0, we can't rest 0 because is the first array element,
      //         so we add 2, because the first stop always will be the owner pickup address
      if (waypoint_order[i] === 0) {
        key = waypoint_order[i];
      } else {
        key = waypoint_order[i] - 1;
      }

      members_route_info[key].pu_stop = i + 2;
    } else {
      // 6.2- If the waypoint order is odd (We know that waypoint correspond to dropoff address)
      if (waypoint_order[i] === 1) {
        key = waypoint_order[i] - 1;
      } else {
        key = waypoint_order[i] - 2;
      }

      members_route_info[key].do_stop = i + 2;
    }

    members_route_info[key].distance =
      members_route_info[key].distance +
      get(legs, `[${waypoint_order[i]}].distance.value`, 0) / 1609;
  }

  // 7- Iterate all legs to add total_miles and individual distance
  legs.forEach((leg) => {
    const distance = get(leg, "distance.value", 0);

    total_miles = total_miles + distance;
  });

  // 8- Make the object result adding owner_route_info and members_route_info
  const routeInfoToSend = {
    group_id: standingOrderWalker.group_id,
    route_info: [owner_route_info, ...members_route_info],
    total_miles: total_miles / 1609,
  };

  return routeInfoToSend;
};
