import React, { useCallback } from "react";
import styled from "styled-components";
import tw from "tailwind.macro";

const Element = styled.span`
  ${tw`border rounded-full flex items-center cursor-pointer w-12`}
`;

const Content = styled.span`
  ${tw`rounded-full border w-6 h-6 shadow-inner bg-white shadow`}
`;

export interface ISwitchHook {
  id?: string;
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

const SwitchHook: React.FC<ISwitchHook> = ({
  name,
  value,
  onChange,
  children,
}) => {
  const handleClick = useCallback(() => {
    onChange(!value);
  }, [onChange, value]);

  const handleOnChange = useCallback(() => {
    onChange(!value);
  }, [onChange, value]);

  return (
    <div className="flex flex-col justify-between" onClick={handleClick}>
      <input
        name={name}
        hidden={true}
        value={String(value)}
        onChange={handleOnChange}
      />

      <Element
        className={`transition-bg transition-all transition-250 ${
          Boolean(value)
            ? "justify-end border-primary-green bg-primary-green"
            : "justify-start border-gray-4 bg-gray-4"
        }`}
      >
        <Content
          className={Boolean(value) ? "border-primary-green" : "border-gray-4"}
        />
      </Element>
      {children}
    </div>
  );
};

export default SwitchHook;
