import React from "react";
import classNames from "classnames";
import styled from "styled-components";

interface IButton
  extends Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: "button" | "submit" | "reset";
  variant?: "primary" | "white" | "black" | "blue" | "cyan";
  disabled?: boolean;
  title?: string;
}

const buttonClasses = ({ variant = "primary", disabled }: IButton) =>
  classNames({
    "px-6 rounded text-white text-sm font-asemibold hover:shadow-button disabled:opacity-40 focus:outline-none active:outline-none": true,
    "bg-primary-green": variant === "primary" && !disabled,
    "bg-primary-green-opacity": variant === "primary" && disabled,
    "bg-white text-gray-1 border-gray-7 border-2": variant === "white",
    "bg-gray-10 text-white": variant === "black",
    "bg-blue-800 hover:bg-blue-900": variant === "blue",
    "bg-cyan text-white": variant === "cyan",
  });

const StyledButton = styled.button.attrs<IButton>(({ className, ...rest }) => ({
  className: `${buttonClasses(rest)} ${className}`,
  ...rest,
}))`
  height: 52px;
`;

const Button: React.FC<IButton> = ({ children, ...rest }) => (
  <StyledButton {...rest}>{children}</StyledButton>
);

export default Button;
