import React, { memo } from "react";
import { Route, Redirect, RouteProps } from "react-router";
import { useSelector } from "react-redux";

import { UserLayout } from "../components/common/Layouts";
import { selectors as appSelectors } from "../store/app";

interface IAuthenticatedRoute {
  component?: React.ComponentType<any>;
}

const AuthenticatedRoute: React.FC<RouteProps & IAuthenticatedRoute> = memo(
  ({ component: Component, children, ...rest }) => {
    const isAuthenticated = useSelector(appSelectors.isAuthenticated);

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated === false ? (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  modal: true,
                },
              }}
            />
          ) : (
            <>
              {Component && (
                <UserLayout>
                  <Component {...props} />
                </UserLayout>
              )}
              {children}
            </>
          )
        }
      />
    );
  },
);

export default AuthenticatedRoute;
