import React, {
  InputHTMLAttributes,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";

import Tab from "../Tab";
import TextField from "../TextField";
import DownIcon from "../common/DownIcon";
import UpIcon from "../common/UpIcon";

const INITIAL_ITEMS = [
  {
    label: "Mon",
    value: "mon",
    active: false,
  },
  {
    label: "Tue",
    value: "tue",
    active: false,
  },
  {
    label: "Wed",
    value: "wed",
    active: false,
  },
  {
    label: "Thu",
    value: "thu",
    active: false,
  },
  {
    label: "Fri",
    value: "fri",
    active: false,
  },
  {
    label: "Sat",
    value: "sat",
    active: false,
  },
  {
    label: "Sun",
    value: "sun",
    active: false,
  },
];

interface IDaysOfWeekSelectHook extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  handleChange: (name: string, value: string[]) => void;
  value: string[];
  placeholder?: string;
}

const DaysOfWeekSelectHook: React.FC<IDaysOfWeekSelectHook> = ({
  value,
  name,
  handleChange,
  placeholder,
  className,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [items, setItems] = useState(INITIAL_ITEMS);
  const ref = useRef<HTMLDivElement>(null);

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e.preventDefault();

    setOpen(true);
  };

  const handleIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setOpen(!open);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const updateItems = useCallback(() => {
    setItems(
      items.map((item: any) => ({
        ...item,
        active: value.includes(item.value),
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateItems();

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [updateItems]);

  const handleClick = (newValue: any) => {
    if (value.includes(newValue)) {
      value.splice(value.indexOf(newValue), 1);
    } else {
      value.push(newValue);
    }

    updateItems();

    handleChange(name, value);
  };

  return (
    <div className="relative z-auto w-ful" ref={ref}>
      <TextField
        id={name}
        name={name}
        onChange={() => ({})}
        onFocus={handleFocus}
        value={value}
        rightIcon={open ? UpIcon : DownIcon}
        onIconClick={handleIconClick}
        placeholder={placeholder}
      />
      {open && (
        <div
          className="absolute left-0 my-4 bg-white rounded-lg z-50"
          style={{ boxShadow: "0 2px 8px 0 rgba(24, 25, 27, 0.1)" }}
        >
          <div className="flex items-center p-4">
            <ul
              className={`flex bg-gray-8 p-1 w-full ${className}`}
              style={{
                height: "52px",
                maxHeight: "52px",
                borderRadius: "5px",
              }}
            >
              {items.map((item: any) => (
                <Tab
                  label={item.label}
                  key={item.label}
                  active={item.active}
                  value={item.value}
                  onClick={handleClick}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DaysOfWeekSelectHook;
