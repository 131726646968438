import React, { memo } from "react";
import { useSelector } from "react-redux";

import { selectors as appSelectors } from "../../../store/app";

import Content from "../Content";
import Footer from "../Footer";
import Notifier from "../Notifier";
import UserHeader from "../UserHeader";
import Loading from "../Loading";
import { ErrorBoundary } from "../ErrorBoundary";

const UserLayout: React.FC = memo(({ children }) => {
  const isLoading = useSelector(appSelectors.isLoading);

  return (
    <>
      {isLoading && <Loading />}
      <Notifier />
      <UserHeader />
      <Content>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Content>
      <Footer />
    </>
  );
});

export default UserLayout;
