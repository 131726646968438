import React from "react";

import classNames from "classnames";

import "./Tab.css";

export interface ITab {
  label: string;
  value: any;
  onClick: (value: any) => void;
  active?: boolean;
}

const tabClasses = (active?: boolean) =>
  classNames({
    Tab,
    "Tab-inactive": !active,
    "Tab-active": active,
  });

const Tab: React.FC<ITab> = ({ label, active, value, onClick }) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();

    onClick(value);
  };

  return (
    <li className={tabClasses(active)}>
      <button className="Tab__label focus:outline-none" onClick={handleClick}>
        {label}
      </button>
    </li>
  );
};

export default Tab;
