import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Dropdown } from "@qryde/components-ui";

import { ReactComponent as AcceptIcon } from "../../../assets/svg/accept-icon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/svg/trash-icon.svg";
import { ReactComponent as EnterInIcon } from "../../../assets/svg/enter-in-icon.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/svg/notification-icon.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/svg/refresh-icon.svg";
import { ReactComponent as RemovedIcon } from "../../../assets/svg/removed-icon.svg";

import { selectors as appSelectors, actions as appActions } from "../../../store/app";
import { TypeNotification, INotification } from "../../../utils/types";

const NotificationIconType: React.FC<{ type: TypeNotification }> = ({ type }) => {
  switch (type) {
    case TypeNotification.CarpoolRejectJoin:
      return <TrashIcon className="mr-2" width="14" height="14" />;
    case TypeNotification.CarpoolRemoved:
      return <RemovedIcon className="mr-2" width="14" height="14" />;
    case TypeNotification.CarpoolRequestAccept:
      return <AcceptIcon className="mr-2" width="14" height="14" />;
    case TypeNotification.CarpoolRequestJoin:
      return <EnterInIcon className="mr-2" width="14" height="14" />;
    case TypeNotification.CarpoolTerminated:
      return <TrashIcon className="mr-2" width="14" height="14" />;
    case TypeNotification.CarpoolUpdated:
      return <RefreshIcon className="mr-2" width="14" height="14" />;
    default:
      return null;
  }
};

const NotificationIconComponent = (notifications: number) =>
  memo(props => (
    <>
      <NotificationIcon {...props} />
      {notifications > 0 && (
        <span className="absolute right-0 top-0 -mt-2 text-xs bg-red-500 text-white font-medium px-2 shadow-lg rounded-full">
          {notifications}
        </span>
      )}
    </>
  ));

const NotificationMenu: React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(appSelectors.notificationsApp);

  const handleOnHideDropdown = () => {
    const notificationsUnReaded = notifications.filter(
      (notification: INotification) => !notification.read,
    );

    if (notificationsUnReaded.length > 0) {
      const notificationsIds = notificationsUnReaded.map((notification: any) => notification.id);

      dispatch(appActions.markNotificationsAsRead(notificationsIds));
    }
  };

  return (
    <Dropdown
      icon={NotificationIconComponent(
        notifications.filter((notification: INotification) => !notification.read).length,
      )}
      avatarBg={false}
      avatarColor="white"
      onHide={handleOnHideDropdown}
    >
      <div className="flex flex-col overflow-y-auto w-full" style={{ maxHeight: "300px" }}>
        {!!notifications.length ? (
          <ul>
            {notifications.map((notification: INotification, index: number) => (
              <li
                className={`flex items-center text-sm font-amedium ${
                  !notification.read ? "text-red-400" : "text-gray-2"
                }`}
                key={index}
              >
                <NotificationIconType type={notification.type} />
                <p>{notification.message}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-sm font-amedium text-black">No notifications</p>
        )}
      </div>
    </Dropdown>
  );
};

export default NotificationMenu;
