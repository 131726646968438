import get from "lodash/get";
import isString from "lodash/isString";

export const getErrorMessage = (error: any) => {
  const errors = get(error, "errors");

  return isString(errors)
    ? errors
    : get(errors, "errors.[0].message", "Something went wrong");
};
