import React from "react";

import { useSelector } from "react-redux";

import carImg from "../../../assets/svg/car.svg";
import cloudImg from "../../../assets/svg/cloud.svg";
import moneyImg from "../../../assets/svg/money.svg";
import fuelImg from "../../../assets/svg/fuel-icon.svg";

import { selectors as appSelectors } from "../../../store/app";

const Banner: React.FC = () => {
  const incentives = useSelector(appSelectors.incentives);

  return (
    <section className="py-12 bg-white container">
      <section className="stats flex justify-between">
        <div className="inline-flex items-center">
          <img src={fuelImg} alt="Car" className="flex mr-4" />
          <div>
            <h4 className="text-4xl font-asemibold text-blue-700">
              {incentives.gasoline || 0}
            </h4>
            <p className="text-gray-500">gallons of gasoline conserved</p>
          </div>
        </div>

        <div className="inline-flex items-center">
          <img src={cloudImg} alt="Car" className="flex mr-4" />
          <div>
            <h4 className="text-4xl font-asemibold text-blue-700">
              {incentives.pollutantCo2 || 0}
            </h4>
            <p className="text-gray-500">tons of emissions prevented</p>
          </div>
        </div>

        <div className="inline-flex items-center">
          <img src={carImg} alt="Car" className="flex mr-4" />
          <div>
            <h4 className="text-4xl font-asemibold text-blue-700">
              {incentives.milesNotDriven || 0}
            </h4>
            <p className="text-gray-500">miles not driven</p>
          </div>
        </div>

        <div className="inline-flex items-center self-end">
          <img src={moneyImg} alt="Car" className="flex mr-4" />
          <div>
            <h4 className="text-4xl font-asemibold text-blue-700">
              ${incentives.rewards || 0}
            </h4>
            <p className="text-gray-500">rewards</p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Banner;
