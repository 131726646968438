// General types (notification, and other types that are in all app)

export enum TypeNotification {
  CarpoolRejectJoin = "carpool_reject_join",
  CarpoolRemoved = "carpool_removed_user",
  CarpoolRequestAccept = "carpool_request_accept",
  CarpoolRequestJoin = "carpool_request_join",
  CarpoolTerminated = "carpool_terminated",
  CarpoolUpdated = "carpool_updated",
  CarpoolChat = "carpool_chat",
}

export interface INotification {
  message: string;
  type: TypeNotification;
  read: boolean;
}

export interface IDPTime {
  hour: string;
  minute: string;
  type: string;
}

export interface ITime {
  pickupTime: IDPTime;
  dropoffTime: IDPTime;
}

export interface ICarpool {
  active: boolean;
  manageExceptions: boolean;
  requests?: number;
  so_id: string;
  clientid: string;
  so_start_date: Date;
  so_end_date: Date;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
  who_booked: string;
  oper_id: string;
  agent: string;
  blind: string;
  walker: string;
  assistant: string;
  attendent: string;
  fare: string;
  disposition: string;
  trip_type: string;
  when_booked: Date;
  pt_tie: string;
  name: string;
  name2: string;
  name3: string;
  baggage_charges: string;
  toll_charges: string;
  service_charges: string;
  est_trav_time: string;
  est_distance: string;
  est_oper_id: string;
  possveh: string;
  collected_fare: string;
  s_county: string;
  e_county: string;
  telephone_pu: string;
  med_verify: string;
  general_comnts2: string;
  fare_override: string;
  pu_odoreading: string;
  do_odoreading: string;
  odd_even: string;
  s_location_ent: string;
  e_location_ent: string;
  e_location1: string;
  num_pass: string;
  purpose: string;
  mobility_list: string;
  driver_notes: string;
  one_way: string;
  trip_count: number;
  per_tripfare: string;
  process_flag: string;
  process_values: string;
  group_id: string;
  grp_cnt_amb: string;
  trip_name_pu: string;
  s_location1: string;
  s_location2: string;
  s_citytown: string;
  s_zip: string;
  s_statepro: string;
  s_country: string;
  e_location2: string;
  e_citytown: string;
  e_zip: string;
  e_statepro: string;
  e_country: string;
  s_grid_lat: string;
  s_grid_long: string;
  e_grid_lat: string;
  e_grid_long: string;
  Driver: any;
  creation_datetime?: Date;
  mon_start_time?: Date;
  mon_desired_end_time?: Date;
  tue_start_time?: Date;
  tue_desired_end_time?: Date;
  wed_start_time?: Date;
  wed_desired_end_time?: Date;
  thu_start_time?: Date;
  thu_desired_end_time?: Date;
  fri_start_time?: Date;
  fri_desired_end_time?: Date;
  sat_start_time?: Date;
  sat_desired_end_time?: Date;
  sun_start_time?: Date;
  sun_desired_end_time?: Date;
  systemdatetime?: Date;
  driver_id?: string;
  general_comnts?: string;
  elderly?: string;
  handicapped?: string;
  escort?: string;
  car_seat?: string;
  pca?: string;
  district?: string;
  dis_other?: string;
  spl_other?: string;
  veh_type?: string;
  vehicle_id?: string;
  oper_changed_by?: string;
  so_history?: string;
  s_location3?: string;
  trip_name?: string;
  e_location3?: string;
  request_message?: string;
  pool_status?: any;
  phone?: string;
  messages?: any[];
  seats_available?: string;
}
