import React from "react";

import "./IconButton.css";
import classNames from "classnames";

interface IIconButton
  extends Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> {
  icon: string | React.FC<any>;
  title: string;
  className?: string;
  rounded?: boolean;
  type?: "button" | "submit" | "reset";
  iconClassname?: string;
  iconWidth?: number | string;
  iconHeight?: number | string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const iconButtonClasses = (props: IIconButton) =>
  classNames({
    IconButton: true,
    "IconButton-rounded": props.rounded,
  });

const IconButton: React.FC<IIconButton> = (props) => {
  const {
    icon: Icon,
    className,
    iconWidth,
    iconHeight,
    iconClassname,
    title,
    rounded,
    ...rest
  } = props;

  return (
    <button
      title={title}
      className={`${iconButtonClasses(props)} ${className}`}
      {...rest}
    >
      {typeof Icon === "string" ? (
        <img alt={title} src={Icon} className="IconButton__icon" />
      ) : (
        <Icon
          title={title}
          className={`IconButton__icon ${iconClassname}`}
          {...(iconWidth &&
            iconHeight && {
              width: iconWidth,
              height: iconHeight,
            })}
        />
      )}
    </button>
  );
};

IconButton.defaultProps = {
  className: "",
};

export default IconButton;
