import get from "lodash/get";
import isEqual from "lodash/isEqual";

import { handleActions } from "../../utils/redux";
import { IState, Types } from "./types";

const INITIAL_STATE: IState = {
  isLoading: false,
  search: {
    homeAddress: "",
    workAddress: "",
    date: new Date(),
    daysOfWeek: ["mon", "tue", "wed", "thu", "fri"],
    time: {
      pickupTime: {
        hour: "09",
        minute: "00",
        type: "AM",
      },
      dropoffTime: {
        hour: "06",
        minute: "00",
        type: "PM",
      },
    },
    otherCanDrive: true,
    nonSmokingCarpool: true,
    startDate: new Date(),
    endDate: new Date(),
    eventDescription: "",
  },
  result: [],
  actives: [],
  pending: [],
  approveds: [],
  edit: null,
  exceptions: [],
  drivers: [],
  messages: {},
  notifications: {}
};

export function buildReducer(types: Types) {
  return handleActions<IState>(INITIAL_STATE, {
    [types.SET_SEARCH_ADDRESS]: (state, action) => ({
      ...state,
      search: {
        ...state.search,
        ...action.payload.address,
      },
    }),
    [types.CHANGE_SEARCH_ADDRESS]: (state, action) => ({
      ...state,
      search: {
        ...state.search,
        ...action.payload.address,
      },
    }),
    [types.SEARCH_CARPOOL]: (state, action) => ({
      ...state,
      isLoading: true,
      result: action.payload.data,
    }),
    [types.SEARCH_CARPOOL_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      result: action.payload.data,
    }),
    [types.SEARCH_CARPOOL_ERROR]: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload.error,
    }),
    [types.CREATE_CARPOOL]: (state, action) => ({
      ...state,
      isLoading: true,
      result: [action.payload.data],
    }),
    [types.CREATE_CARPOOL_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      result: [action.payload.data],
    }),
    [types.CREATE_CARPOOL_ERROR]: (state, action) => ({
      ...state,
      isLoading: false,
      result: [action.payload.data],
    }),
    [types.JOIN_CARPOOL]: (state, action) => ({
      ...state,
      join: action.payload.carpool,
    }),
    [types.GET_ACTIVES_CARPOOL]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [types.GET_ACTIVES_CARPOOL_SUCCESS]: (state, action) => {
      const data = get(action.payload, "data", []);

      return {
        ...state,
        actives: data.filter(
          (item: any) => item.pool_status === "APPROVED" || item.pool_status === null,
        ),
        pending: data.filter((item: any) => item.pool_status === "PENDING"),
        isLoading: false,
      }
    },
    [types.GET_DATA_CARPOOL]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [types.GET_DATA_CARPOOL_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.carpool,
      isLoading: false,
    }),
    [types.ACCEPT_REQUEST_CARPOOL]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [types.ACCEPT_REQUEST_CARPOOL_SUCCESS]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        requests: state.data.requests.filter(
          (request: any) => request.so_id !== action.payload.data.so_id,
        ),
      },
      isLoading: false,
    }),
    [types.REJECT_REQUEST_CARPOOL_SUCCESS]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        requests: state.data.requests.filter(
          (request: any) => request.so_id !== action.payload.data.so_id,
        ),
      },
      isLoading: false,
    }),
    [types.GET_APPROVED_REQUESTS_CARPOOL_SUCCESS]: (state, action) => ({
      ...state,
      approveds: action.payload.carpool,
    }),
    [types.SHOW_EDIT_CARPOOL]: (state, action) => ({
      ...state,
      edit: action.payload.data,
    }),
    [types.GET_EXCEPTIONS_CARPOOL]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [types.GET_EXCEPTIONS_CARPOOL_SUCCESS]: (state, action) => ({
      ...state,
      exceptions: action.payload.data,
      isLoading: false,
    }),
    [types.ADD_EXCEPTION_CARPOOL_SUCCESS]: (state, action) => ({
      ...state,
      exceptions: state.exceptions!.concat(action.payload.data),
    }),
    [types.REMOVE_EXCEPTION_CARPOOL_SUCCESS]: (state, action) => ({
      ...state,
      exceptions: state.exceptions!.filter(exception => !isEqual(exception, action.payload.data)),
    }),
    [types.GET_AVAILABLE_DRIVER_CARPOOL_SUCCESS]: (state, action) => ({
      ...state,
      drivers: action.payload.data,
    }),
    [types.GET_CARPOOL_MESSAGES_SUCCESS]: (state, action) => {
      const { group_id, messages } = action.payload.data;

      return {
        ...state,
        messages: { ...state.messages, [group_id]: messages },
      };
    },

    // Websocket reducers
    [types.UPDATE_ACTIVES_CARPOOL]: (state, action) => ({
      ...state,
      actives: action.payload.actives ?? [],
    }),
    [types.WS_CARPOOL_NEW_MESSAGE]: (state, action) => {
      const message = {
        ...action.payload.message,
        sender: action.payload.message.userid,
        createdAt: new Date().toLocaleString("en-US"),
      };

      return {
        ...state,
        messages: {
          ...state.messages,
          [message.group_id]: [
            ...state.messages[message.group_id],
            {...message},
          ],
        },
      };
    },
  });
}
