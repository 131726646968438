import { createAction, createActionTypeScoper } from "../../utils/redux";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    LOADING: scopeActionType("LOADING"),
    LOADING_FINISH: scopeActionType("LOADING_FINISH"),
    SET_TOKEN: scopeActionType("SET_TOKEN"),
    LOGIN: scopeActionType("LOGIN"),
    LOGIN_FACEBOOK: scopeActionType("LOGIN_FACEBOOK"),
    LOGIN_GOOGLE: scopeActionType("LOGIN_GOOGLE"),
    LOGIN_TWITTER: scopeActionType("LOGIN_TWITTER"),
    LOGIN_SUCCESS: scopeActionType("LOGIN_SUCCESS"),
    LOGIN_ERROR: scopeActionType("LOGIN_ERROR"),
    CLEAR_LOGIN_ERROR: scopeActionType("CLEAR_LOGIN_ERROR"),
    IS_AUTHENTICATED: scopeActionType("IS_AUTHENTICATED"),
    LOGOUT: scopeActionType("LOGOUT"),
    LOGOUT_SUCCESS: scopeActionType("LOGOUT_SUCCESS"),
    LOGOUT_ERROR: scopeActionType("LOGOUT_ERROR"),
    REGISTER: scopeActionType("REGISTER"),
    REGISTER_SUCCESS: scopeActionType("REGISTER_SUCCESS"),
    REGISTER_ERROR: scopeActionType("REGISTER_ERROR"),
    CONFIRM_EMAIL: scopeActionType("CONFIRM_EMAIL"),
    UPDATE_USER: scopeActionType("UPDATE_USER"),
    UPDATE_USER_SUCCESS: scopeActionType("UPDATE_USER_SUCCESS"),
    UPDATE_USER_ERROR: scopeActionType("UPDATE_USER_ERROR"),
    ENQUEUE_SNACKBAR: scopeActionType("ENQUEUE_SNACKBAR"),
    CLOSE_SNACKBAR: scopeActionType("CLOSE_SNACKBAR"),
    REMOVE_SNACKBAR: scopeActionType("REMOVE_SNACKBAR"),
    SET_NOTIFICATIONS: scopeActionType("SET_NOTIFICATIONS"),
    ENQUEUE_NOTIFICATION: scopeActionType("ENQUEUE_NOTIFICATION"),
    MARK_NOTIFICATIONS_AS_READ: scopeActionType("MARK_NOTIFICATIONS_AS_READ"),
    CLEAR_NOTIFICATION: scopeActionType("CLEAR_NOTIFICATION"),
    LOAD_INCENTIVES: scopeActionType("LOAD_INCENTIVES"),
    VALIDATE_PIN: scopeActionType("VALIDATE_PIN"),
    SET_PASSWORD: scopeActionType("SET_PASSWORD"),
    TOGGLE_COVID_ALERT: scopeActionType("TOGGLE_COVID_ALERT"),
  };

  const actions = {
    loading: createAction(types.LOADING),
    setToken: createAction(types.SET_TOKEN, (token: any) => ({
      token,
    })),
    loadingFinish: createAction(types.LOADING_FINISH),
    login: createAction(types.LOGIN, (userData: any) => ({
      userData,
    })),
    loginSuccess: createAction(types.LOGIN_SUCCESS, (user: any) => ({
      user,
    })),
    loginError: createAction(types.LOGIN_ERROR, (error: any) => ({
      error,
    })),
    clearLoginError: createAction(types.CLEAR_LOGIN_ERROR),
    loginFacebook: createAction(types.LOGIN_FACEBOOK, (profile: any) => ({
      profile,
    })),
    loginGoogle: createAction(types.LOGIN_GOOGLE, (profile: any) => ({
      profile,
    })),
    loginTwitter: createAction(types.LOGIN_TWITTER),
    isAuthenticated: createAction(types.IS_AUTHENTICATED),
    logout: createAction(types.LOGOUT),
    logoutSuccess: createAction(types.LOGOUT_SUCCESS),
    logoutError: createAction(types.LOGIN_ERROR, (error: any) => ({
      error,
    })),
    register: createAction(types.REGISTER, (userData: any) => ({
      userData,
    })),
    registerSuccess: createAction(types.REGISTER_SUCCESS),
    registerError: createAction(types.REGISTER_ERROR, (error: any) => ({
      error,
    })),
    confirmEmail: createAction(types.CONFIRM_EMAIL, (token: any) => ({
      token,
    })),
    updateUser: createAction(types.UPDATE_USER, (userData: any) => ({
      userData,
    })),
    enqueueSnackbar: createAction(types.ENQUEUE_SNACKBAR, (snackbar: any) => {
      const key = snackbar.options && snackbar.options.key;

      return {
        snackbar: {
          ...snackbar,
          key: key || new Date().getTime() + Math.random(),
        },
      };
    }),
    closeSnackbar: createAction(types.CLOSE_SNACKBAR, (key: string) => ({
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    })),
    removeSnackbar: createAction(types.REMOVE_SNACKBAR, (key: string) => ({
      key,
    })),
    setNotifications: createAction(types.SET_NOTIFICATIONS, (data: any) => ({
      data,
    })),
    loadIncentives: createAction(types.LOAD_INCENTIVES, (incentives: any) => ({
      incentives,
    })),
    validatePin: createAction(types.VALIDATE_PIN, (data: any) => ({
      data,
    })),
    setPassword: createAction(types.SET_PASSWORD, (data: any) => ({
      data,
    })),
    // websockets actions
    enqueueNotification: createAction(types.ENQUEUE_NOTIFICATION, (notification: any) => ({
      notification,
    })),
    clearNotifications: createAction(types.CLEAR_NOTIFICATION),
    markNotificationsAsRead: createAction(
      types.MARK_NOTIFICATIONS_AS_READ,
      (notification: any) => ({
        notification,
      }),
    ),
    toggleCovidAlert: createAction(types.TOGGLE_COVID_ALERT),
  };

  return { types, actions };
}
