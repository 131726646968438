import React from "react";

import "./TextField.css";
import TextFieldWithIcon from "./TextFieldWithIcon";
import TextFieldBase from "./TextFieldBase";

export interface ITextField {
  id: string;
  name: string;
  value: any;
  rows?: number;
  label?: string;
  touched?: boolean;
  errors?: any;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  rightIcon?: React.FC;
  leftIcon?: React.FC;
  className?: string;
  maxLength?: number;
  max?: number;
  tabIndex?: number;
  readOnly?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onIconClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyPress?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const TextField: React.FC<ITextField> = (props) => {
  const { type = "string", rightIcon, leftIcon } = props;

  if (rightIcon || leftIcon) {
    return <TextFieldWithIcon type={type} {...props} />;
  }

  return <TextFieldBase type={type} {...props} />;
};

export default TextField;
