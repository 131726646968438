import React from "react";

import classNames from "classnames";

import { ITextField } from "./TextField";
import "./TextField.css";

export const textFieldClasses = (props: ITextField) =>
  classNames({
    TextField__input: true,
    "TextField__input-valid": props.touched && !props.errors,
    "TextField__input-error": props.touched && props.errors,
  });

const TextFieldBase: React.FC<ITextField> = (props) => {
  const { touched, errors, label: labelText, name, rows, ...rest } = props;

  return (
    <div
      className={`TextField__wrapper ${
        Boolean(rest.className) && rest.className
      }`}
    >
      {Boolean(labelText) && (
        <label htmlFor={rest.id} className="TextField__label">
          {labelText}{" "}
          {rest.required && (
            <span className="TextField__label-required">*</span>
          )}
        </label>
      )}
      {rows ? (
        <textarea
          {...rest}
          name={name}
          autoComplete={name}
          className={`${textFieldClasses(props)} max-h-full`}
          rows={rows}
        />
      ) : (
        <input
          {...rest}
          name={name}
          autoComplete={name}
          className={textFieldClasses(props)}
        />
      )}
      {touched && errors && (
        <div className="TextField__input__errors__wrapper">
          <span className="TextField__input__errors">{errors}</span>
        </div>
      )}
    </div>
  );
};

export default TextFieldBase;
