import React from "react";

import { Link } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Banner from "../Banner";

import "./Footer.css";
import { selectors as appSelectors } from "../../../store/app";

const Footer: React.FC = () => {
  const isAuthenticated = useSelector(appSelectors.isAuthenticated);

  return (
    <footer className="Footer">
      {isAuthenticated && <Banner />}
      <div className="Footer__container container">
        <ul className="Footer__menu">
          <li className="Footer__menu__item">
            <Link className="Footer__menu__item__link-active" to="/about">
              <Trans i18nKey="component.footer.about_us">About us</Trans>
            </Link>
          </li>
          <li className="Footer__menu__item">
            <Link className="Footer__menu__item__link" to="">
              <Trans i18nKey="component.footer.contact_us">Contact Us</Trans>
            </Link>
          </li>
          <li className="Footer__menu__item">
            <Link className="Footer__menu__item__link" to="/faq">
              <Trans i18nKey="component.footer.feedback">FAQ</Trans>
            </Link>
          </li>
          <li className="Footer__menu__item">
            <Link className="Footer__menu__item__link" to="/terms-and-use">
              <Trans i18nKey="component.footer.terms_conditions">
                {"Terms & Conditions"}
              </Trans>
            </Link>
          </li>
          <li className="Footer__menu__item">
            <Link className="Footer__menu__item__link" to="/privacy-policy">
              <Trans i18nKey="component.footer.privacy_policy">
                {"Privacy & Policy"}
              </Trans>
            </Link>
          </li>
          <li className="Footer__menu__item">
            <Link className="Footer__menu__item__link" to="">
              <Trans i18nKey="component.footer.web_browsers">
                Web Browsers
              </Trans>
            </Link>
          </li>
          <li className="Footer__menu__item">
            <Link className="Footer__menu__item__link" to="/calculator">
              <Trans i18nKey="component.footer.calculator">
                Calculator
              </Trans>
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

Footer.displayName = "Footer";

export default withTranslation()(Footer);
