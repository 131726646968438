import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { Logo, Dropdown, Select } from "@qryde/components-ui";

import { ReactComponent as ProfileIcon } from "../../../assets/svg/profile-icon.svg";
import { actions as appActions, selectors as appSelectors } from "../../../store/app";
import { selectors as communitySelectors } from "../../../store/community";
import LanguageMenu from "../LanguageMenu";
import NotificationMenu from "../NotificationMenu";
import Logger from '../../../utils/logger';

const UserHeader: React.FC = () => {
  const user = useSelector(appSelectors.user);
  const communities = useSelector(communitySelectors.communities);
  const actualCommunity = useSelector(communitySelectors.community);
  const actualCommunityUser = useSelector(communitySelectors.user);
  const dispatch = useDispatch();

  const handleLogoutButtonClick = () => {
    dispatch(appActions.logout());
  };

  const handleSelectCommunityChange = (_name: string, value: any) => {
    Logger.info(value);
  };

  const isCommunityAdmin = actualCommunityUser && actualCommunityUser.admin;

  return (
    <header className="bg-dark-blue py-6 text-gray-4">
      <div className="flex flex-wrap justify-between items-center container">
        <Link to="/">
          <Logo version="3.0" description="Rider" />
        </Link>
        <nav className="text-white flex items-center">
          <ul>
            <li className="inline-flex mx-6 font-aregular text-sm text-gray-4 hover:text-primary-green">
              <Link to="/transportation-modes">
                <Trans i18nKey="component.user-header.transportation-modes">
                  Transportation Modes
                </Trans>
              </Link>
            </li>
            {!isCommunityAdmin && (
              <li className="inline-flex mx-6 font-aregular text-sm text-gray-4 hover:text-primary-green">
                <Link to="/rides">
                  <Trans i18nKey="component.user_header.rides">Rides</Trans>
                </Link>
              </li>
            )}
            <li className="inline-flex mx-6 font-aregular text-sm text-gray-4 hover:text-primary-green">
              <Link to="/faq">
                <Trans i18nKey="component.user_header.faq">FAQ</Trans>
              </Link>
            </li>
          </ul>
          <div className="ml-4">
            <LanguageMenu />
          </div>
          <div className="mr-4">
            <NotificationMenu />
          </div>
          {actualCommunity && (
            <div className="w-48 " data-tip={true} data-for="community">
              <Select
                id="communityId"
                name="communityId"
                placeholder="Community"
                onChange={handleSelectCommunityChange}
                value={actualCommunity.communityId}
                options={communities.map((community: any) => ({
                  label: community.name,
                  value: community.communityId,
                }))}
                data-tip={true}
                data-for="community"
              />
              <ReactTooltip id="community" type="success" effect="solid" place="left">
                <span>Select a Community</span>
              </ReactTooltip>
            </div>
          )}
          <span
            className="mx-3 bg-gray-2 inline-block"
            style={{ height: "32px", width: "1px", borderRadius: "0.5px" }}
          />
          <Dropdown icon={ProfileIcon} title={user.fullname !== " " ? user.fullname : user.email}>
            {Boolean(isCommunityAdmin) && (
              <Link
                className="block text-gray-2 mb-4 cursor-pointer hover:text-primary-green"
                to="/admin"
              >
                <Trans i18nKey="component.user_header.administrator">Administrator</Trans>
              </Link>
            )}
            <Link
              className="block text-gray-2 mb-4 cursor-pointer hover:text-primary-green"
              to="/profile"
            >
              <Trans i18nKey="component.user_header.profile">Profile</Trans>
            </Link>
            <Link
              className="block text-gray-2 mb-4 cursor-pointer hover:text-primary-green"
              to="/profile/change-password"
            >
              <Trans i18nKey="component.user_header.change-password">Change Password</Trans>
            </Link>
            <hr className="mb-4" />
            <button className="block text-gray-4 cursor-pointer" onClick={handleLogoutButtonClick}>
              <Trans i18nKey="component.user_header.logout_button">Logout</Trans>
            </button>
          </Dropdown>
        </nav>
      </div>
    </header>
  );
};

export default withTranslation()(UserHeader);
