import React from "react";

import classNames from "classnames";

import "./Typography.css";

export interface ITypography {
  variant?: "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const typographyClasses = (variant: ITypography["variant"]) =>
  classNames({
    Typography: variant === "p",
    "Typography-h1": variant === "h1",
    "Typography-h2": variant === "h2",
    "Typography-h3": variant === "h3",
    "Typography-h4": variant === "h4",
    "Typography-h5": variant === "h5",
    "Typography-h6": variant === "h6",
  });

const Typography: React.FC<ITypography> = ({ children, variant = "p" }) => {
  switch (variant) {
    case "h1":
      return <h1 className={typographyClasses(variant)}>{children}</h1>;
    case "h2":
      return <h2 className={typographyClasses(variant)}>{children}</h2>;
    case "h3":
      return <h3 className={typographyClasses(variant)}>{children}</h3>;
    case "h4":
      return <h4 className={typographyClasses(variant)}>{children}</h4>;
    case "h5":
      return <h5 className={typographyClasses(variant)}>{children}</h5>;
    case "h6":
      return <h6 className={typographyClasses(variant)}>{children}</h6>;
    default:
      return <p>{children}</p>;
  }
};

export default Typography;
