import React, { InputHTMLAttributes } from "react";

import "./CheckboxHook.css";

interface ICheckbox extends InputHTMLAttributes<HTMLInputElement> {
  register: any;
  label?: string;
  touched?: boolean;
  errors?: any;
}

const Checkbox: React.FC<ICheckbox> = ({
  children,
  value,
  name,
  id,
  touched,
  errors,
  onChange,
  register,
}) => {
  return (
    <>
      <label className="Checkbox flex justify-start items-start">
        <div
          className={`bg-white border rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 ${
            touched && errors && `border-red-1`
          }`}
        >
          <input
            type="checkbox"
            className="opacity-0 absolute"
            name={name}
            onChange={onChange}
            id={id}
            value={value}
            ref={register}
          />
          <svg
            className="fill-current hidden h-full w-full text-green-500 pointer-events-auto"
            viewBox="0 0 20 20"
          >
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
          </svg>
        </div>
        <div className="select-none">{children}</div>
      </label>
      {touched && errors && (
        <div className="flex justify-end w-full">
          <span className="font-asemibold text-xs text-red-1">{errors}</span>
        </div>
      )}
    </>
  );
};

export default Checkbox;
