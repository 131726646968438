import storage from "../utils/storage";

export class CommunityService {
  public static async register(community: any) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users/community/register`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(community),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async addMembers(members: any) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users/community/add_users`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(members),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async getAssets(communityId: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/community/get_assets?communityId=${communityId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async saveAssets(asset: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/community/save_assets`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(asset),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async getMembers(communityId: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/community/get_users?communityId=${communityId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async editMember(member: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/community/update_user`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(member),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async deleteMember(member: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/community/delete_user`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(member),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async editCommunity(data: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/community/update_info`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }
}
