import { all, fork } from "redux-saga/effects";

import * as app from "./app";
import * as carpool from "./carpool";
import * as community from "./community";
import * as transportationMode from "./transportationMode";

export default function* rootSaga() {
  yield all([
    fork(app.saga),
    fork(carpool.saga),
    fork(community.saga),
    fork(transportationMode.saga),
  ]);
}
