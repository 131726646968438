import React from "react";

import "./Logo.css";

interface ILogo {
  description?: string;
  version?: string;
}

const Logo: React.FC<ILogo> = ({ description }) => (
  <div className="Logo">
    <span className="Logo__title">HIRideshare</span>
    {description && (
      <>
        <span className="Logo__separator" />
        <span className="Logo__description">{description}</span>
      </>
    )}
  </div>
);

export default Logo;
