import { createAction, createActionTypeScoper } from "../../utils/redux";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    SEARCH_CARPOOL: scopeActionType("SEARCH_CARPOOL"),
    SEARCH_CARPOOL_SUCCESS: scopeActionType("SEARCH_CARPOOL_SUCCESS"),
    SEARCH_CARPOOL_ERROR: scopeActionType("SEARCH_CARPOOL_ERROR"),
    CREATE_CARPOOL: scopeActionType("CREATE_CARPOOL"),
    CREATE_CARPOOL_SUCCESS: scopeActionType("CREATE_CARPOOL_SUCCESS"),
    CREATE_CARPOOL_ERROR: scopeActionType("CREATE_CARPOOL_ERROR"),
    DONT_WANNA_CREATE_CARPOOL: scopeActionType("DONT_WANNA_CREATE_CARPOOL"),
    JOIN_CARPOOL: scopeActionType("JOIN_CARPOOL"),
    GET_DATA_CARPOOL: scopeActionType("GET_DATA_CARPOOL"),
    GET_DATA_CARPOOL_SUCCESS: scopeActionType("GET_DATA_CARPOOL_SUCCESS"),
    REQUEST_JOIN_CARPOOL: scopeActionType("REQUEST_JOIN_CARPOOL"),
    GET_ACTIVES_CARPOOL: scopeActionType("GET_ACTIVES_CARPOOL"),
    GET_ACTIVES_CARPOOL_SUCCESS: scopeActionType("GET_ACTIVES_CARPOOL_SUCCESS"),
    ACCEPT_REQUEST_CARPOOL: scopeActionType("ACCEPT_REQUEST_CARPOOL"),
    ACCEPT_DRIVE_AND_JOIN_REQUEST_CARPOOL: scopeActionType("ACCEPT_DRIVE_AND_JOIN_REQUEST_CARPOOL"),
    ACCEPT_REQUEST_CARPOOL_SUCCESS: scopeActionType("ACCEPT_REQUEST_CARPOOL_SUCCESS"),
    REJECT_REQUEST_CARPOOL: scopeActionType("REJECT_REQUEST_CARPOOL"),
    REJECT_REQUEST_CARPOOL_SUCCESS: scopeActionType("REJECT_REQUEST_CARPOOL_SUCCESS"),
    SET_SEARCH_ADDRESS: scopeActionType("SET_SEARCH_ADDRESS"),
    CHANGE_SEARCH_ADDRESS: scopeActionType("CHANGE_SEARCH_ADDRESS"),
    GET_APPROVED_REQUESTS_CARPOOL: scopeActionType("GET_APPROVED_REQUESTS_CARPOOL"),
    GET_APPROVED_REQUESTS_CARPOOL_SUCCESS: scopeActionType("GET_APPROVED_REQUESTS_CARPOOL_SUCCESS"),
    TERMINATE_CARPOOL: scopeActionType("TERMINATE_CARPOOL"),
    TERMINATE_CARPOOL_SUCCESS: scopeActionType("TERMINATE_CARPOOL_SUCCESS"),
    SHOW_EDIT_CARPOOL: scopeActionType("SHOW_EDIT_CARPOOL"),
    EDIT_CARPOOL: scopeActionType("EDIT_CARPOOL"),
    EDIT_CARPOOL_SUCCESS: scopeActionType("EDIT_CARPOOL_SUCCESS"),
    GET_EXCEPTIONS_CARPOOL: scopeActionType("GET_EXCEPTIONS_CARPOOL"),
    GET_EXCEPTIONS_CARPOOL_SUCCESS: scopeActionType("GET_EXCEPTIONS_CARPOOL_SUCCESS"),
    ADD_EXCEPTION_CARPOOL: scopeActionType("ADD_EXCEPTION_CARPOOL"),
    ADD_EXCEPTION_CARPOOL_SUCCESS: scopeActionType("ADD_EXCEPTION_CARPOOL_SUCCESS"),
    REMOVE_EXCEPTION_CARPOOL: scopeActionType("REMOVE_EXCEPTION_CARPOOL"),
    REMOVE_EXCEPTION_CARPOOL_SUCCESS: scopeActionType("REMOVE_EXCEPTION_CARPOOL_SUCCESS"),
    GET_AVAILABLE_DRIVER_CARPOOL: scopeActionType("GET_AVAILABLE_DRIVER_CARPOOL"),
    GET_AVAILABLE_DRIVER_CARPOOL_SUCCESS: scopeActionType("GET_AVAILABLE_DRIVER_CARPOOL_SUCCESS"),
    REMOVE_CARPOOL: scopeActionType("REMOVE_CARPOOL"),
    REMOVE_CARPOOL_SUCCESS: scopeActionType("REMOVE_CARPOOL_SUCCESS"),
    GET_CARPOOL_MESSAGES: scopeActionType("GET_CARPOOL_MESSAGES"),
    GET_CARPOOL_MESSAGES_SUCCESS: scopeActionType("GET_CARPOOL_MESSAGES_SUCCESS"),
    SET_CARPOOL_MESSAGES_SUCCESS: scopeActionType("SET_CARPOOL_MESSAGES_SUCCESS"),
    UPDATE_ACTIVES_CARPOOL: scopeActionType("UPDATE_ACTIVES_CARPOOL"),

    WS_CARPOOL_NEW_MESSAGE: scopeActionType("WS_CARPOOL_NEW_MESSAGE"),
    WS_CARPOOL_UPDATED: scopeActionType("WS_CARPOOL_UPDATED"),
    WS_CARPOOL_REQUEST_JOIN: scopeActionType("WS_CARPOOL_REQUEST_JOIN"),
    WS_CARPOOL_REQUEST_ACCEPT: scopeActionType("WS_CARPOOL_REQUEST_ACCEPT"),
    WS_CARPOOL_REJECT_JOIN: scopeActionType("WS_CARPOOL_REJECT_JOIN"),
    WS_CARPOOL_TERMINATED: scopeActionType("WS_CARPOOL_TERMINATED"),
    WS_CARPOOL_REMOVED_USER: scopeActionType("WS_CARPOOL_REMOVED_USER"),
  };

  const actions = {
    searchCarpool: createAction(types.SEARCH_CARPOOL, (data: any) => ({
      data,
    })),
    searchCarpoolSuccess: createAction(types.SEARCH_CARPOOL_SUCCESS, (data: any) => ({
      data,
    })),
    searchCarpoolError: createAction(types.SEARCH_CARPOOL_ERROR, (error: any) => ({
      error,
    })),
    createCarpool: createAction(types.CREATE_CARPOOL, (data: any) => ({
      data,
    })),
    createCarpoolSuccess: createAction(types.CREATE_CARPOOL_SUCCESS, (data: any) => ({
      data,
    })),
    createCarpoolError: createAction(types.CREATE_CARPOOL_ERROR, (error: any) => ({
      error,
    })),
    dontWannaCreateCarpool: createAction(types.DONT_WANNA_CREATE_CARPOOL),
    joinCarpool: createAction(types.JOIN_CARPOOL, (carpool: any) => ({
      carpool,
    })),
    getDataCarpool: createAction(types.GET_DATA_CARPOOL, (carpool: any) => ({
      carpool,
    })),
    getDataCarpoolSuccess: createAction(types.GET_DATA_CARPOOL_SUCCESS, (carpool: any) => ({
      carpool,
    })),
    requestJoinCarpool: createAction(types.REQUEST_JOIN_CARPOOL, (data: any) => ({
      data,
    })),
    getActivesCarpool: createAction(types.GET_ACTIVES_CARPOOL),
    getActivesCarpoolSuccess: createAction(types.GET_ACTIVES_CARPOOL_SUCCESS, (data: any) => ({
      data,
    })),
    acceptRequestCarpool: createAction(types.ACCEPT_REQUEST_CARPOOL, (data: any) => ({
      data,
    })),
    acceptJoinAndDriveRequestCarpool: createAction(
      types.ACCEPT_DRIVE_AND_JOIN_REQUEST_CARPOOL,
      (data: any) => ({
        data,
      }),
    ),
    acceptRequestCarpoolSuccess: createAction(
      types.ACCEPT_REQUEST_CARPOOL_SUCCESS,
      (data: any) => ({
        data,
      }),
    ),
    rejectRequestCarpool: createAction(types.REJECT_REQUEST_CARPOOL, (data: any) => ({
      data,
    })),
    rejectRequestCarpoolSuccess: createAction(
      types.REJECT_REQUEST_CARPOOL_SUCCESS,
      (data: any) => ({
        data,
      }),
    ),
    setSearchAddress: createAction(types.SET_SEARCH_ADDRESS, (address: any) => ({
      address,
    })),
    changeSearchAddress: createAction(types.CHANGE_SEARCH_ADDRESS, (address: any) => ({
      address,
    })),
    getApprovedRequestsCarpool: createAction(
      types.GET_APPROVED_REQUESTS_CARPOOL,
      (carpool: any) => ({
        carpool,
      }),
    ),
    getApprovedRequestsCarpoolSuccess: createAction(
      types.GET_APPROVED_REQUESTS_CARPOOL_SUCCESS,
      (carpool: any) => ({
        carpool,
      }),
    ),
    terminateCarpool: createAction(types.TERMINATE_CARPOOL, (data: any) => ({
      data,
    })),
    terminateCarpoolSuccess: createAction(types.TERMINATE_CARPOOL_SUCCESS, (data: any) => ({
      data,
    })),
    showEditCarpool: createAction(types.SHOW_EDIT_CARPOOL, (data: any) => ({
      data,
    })),
    editCarpool: createAction(types.EDIT_CARPOOL, (data: any) => ({
      data,
    })),
    getExceptionsCarpool: createAction(types.GET_EXCEPTIONS_CARPOOL, (data: any) => ({
      data,
    })),
    getExceptionsCarpoolSuccess: createAction(
      types.GET_EXCEPTIONS_CARPOOL_SUCCESS,
      (data: any) => ({
        data,
      }),
    ),
    addExceptionCarpool: createAction(types.ADD_EXCEPTION_CARPOOL, (data: any) => ({
      data,
    })),
    addExceptionCarpoolSuccess: createAction(types.ADD_EXCEPTION_CARPOOL_SUCCESS, (data: any) => ({
      data,
    })),
    removeExceptionCarpool: createAction(types.REMOVE_EXCEPTION_CARPOOL, (data: any) => ({
      data,
    })),
    removeExceptionCarpoolSuccess: createAction(
      types.REMOVE_EXCEPTION_CARPOOL_SUCCESS,
      (data: any) => ({
        data,
      }),
    ),
    getAvailableDriverCarpool: createAction(types.GET_AVAILABLE_DRIVER_CARPOOL, (data: any) => ({
      data,
    })),
    getAvailableDriverCarpoolSuccess: createAction(
      types.GET_AVAILABLE_DRIVER_CARPOOL_SUCCESS,
      (data: any) => ({
        data,
      }),
    ),
    removeCarpool: createAction(types.REMOVE_CARPOOL, (data: any) => ({
      data,
    })),
    removeCarpoolSuccess: createAction(types.REMOVE_CARPOOL_SUCCESS, (data: any) => ({
      data,
    })),
    getCarpoolMessages: createAction(types.GET_CARPOOL_MESSAGES, (data: any) => ({
      data,
    })),
    getCarpoolMessagesSuccess: createAction(types.GET_CARPOOL_MESSAGES_SUCCESS, (data: any) => ({
      data,
    })),

    // Websocket actions
    updateActivesCarpool: createAction(types.UPDATE_ACTIVES_CARPOOL, (actives: any) => ({
      actives,
    })),
    wsCarpoolNewMessage: createAction(types.WS_CARPOOL_NEW_MESSAGE, (message: any) => ({
      message,
    })),
    wsCarpoolUpdated: createAction(types.WS_CARPOOL_UPDATED, (data: any) => ({
      data
    })),
    wsCarpoolRequestAccept: createAction(types.WS_CARPOOL_REQUEST_ACCEPT, (data: any) => ({
      data
    })),
    wsCarpoolRejectJoin: createAction(types.WS_CARPOOL_REJECT_JOIN, (data: any) => ({
      data
    })),
    wsCarpoolRemovedUser: createAction(types.WS_CARPOOL_REMOVED_USER, (data: any) => ({
      data
    })),
    wsCarpoolRequestJoin: createAction(types.WS_CARPOOL_REQUEST_JOIN, (data: any) => ({
      data
    })),
    wsCarpoolTerminated: createAction(types.WS_CARPOOL_TERMINATED, (data: any) => ({
      data
    })),
  };

  return { types, actions };
}
