import React from "react";
import { Alert } from "@qryde/components-ui";
import { useDispatch, useSelector } from "react-redux";
import { actions as appActions, selectors as appSelectors } from "../../../store/app";
import { SnackbarProviderProps } from "notistack";

export const SNACKBAR_ANCHOR_ORIGIN: SnackbarProviderProps["anchorOrigin"] = {
  vertical: "top",
  horizontal: "center",
}

const Snackbar = React.forwardRef((props: any, ref) => {
  const dispatch = useDispatch();
  const snackbars = useSelector(appSelectors.snackbars);

  const handleDismiss = () => {
    dispatch(appActions.closeSnackbar(props.id));
  };

  const snackbarProps = snackbars.find(
    (snackbar: any) => snackbar.key === props.id,
  );
  const snackbarActions = [
    {
      title: "Close",
      onClick: handleDismiss,
    },
  ];

  return (
    <div ref={ref as any} key={props.key}>
      <Alert
        {...snackbarProps}
        key={props.key}
        actions={snackbarActions}
      />
    </div>
  );
});

export default Snackbar;
