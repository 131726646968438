import React from "react";

import "./InfoBox.css";

interface IInfoBox {
  name: string;
  description?: string | JSX.Element[];
  icon?: React.FunctionComponent<any>;
  image?: string;
  className?: string;
}

const InfoBox: React.FC<IInfoBox> = ({
  name,
  description,
  icon: Icon,
  className,
  image,
}) => (
  <div className={`flex items-center ${className && className}`}>
    {Icon && (
      <div className="flex justify-center flex-col h-full mr-4">
        <div className="rounded-full flex items-center justify-center">
          <Icon title={name} />
        </div>
      </div>
    )}
    {image && (
      <div className="flex justify-center flex-col h-full mr-4">
        <div>
          <img src={image} title={name} alt={name} />
        </div>
      </div>
    )}
    <div className="flex justify-center flex-col h-full">
      <div>{description}</div>
      <span className="font-asemibold text-xs text-gray-4 uppercase">
        {name}
      </span>
    </div>
  </div>
);

export default InfoBox;
