import React, { useState, useRef, useEffect } from "react";

import PlacesAutocomplete, { PropTypes } from "react-places-autocomplete";

import classNames from "classnames";

import "./SelectPlaces.css";

export const textFieldClasses = (props: SelectPlaces) =>
  classNames({
    SelectPlaces__input: true,
    "SelectPlaces__input-valid":
      Boolean(props.touched) && props.errors === undefined,
    "SelectPlaces__input-error": Boolean(props.touched) && props.errors,
  });

export interface SelectPlaces {
  id: string;
  name: string;
  value: string;
  onChange: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => any;
  onBlur?: (eventOrString: any) => void | ((e: any) => void);
  required?: boolean;
  label?: string;
  className?: string;
  placeholder?: string;
  touched?: boolean;
  errors?: any;
  disabled?: boolean;
}

const SelectPlaces: React.FC<SelectPlaces> = (props) => {
  const {
    id,
    name,
    value,
    onChange,
    label: labelText,
    required,
    className,
    placeholder,
    errors,
    touched,
    disabled,
  } = props;
  const [inputValue, setInputValue] = useState<string>(value);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  });

  if (typeof value !== "string") {
    return null;
  }

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setInputValue(value);
    }
  };

  const renderFunc: PropTypes["children"] = ({
    getInputProps,
    getSuggestionItemProps,
    suggestions,
    loading,
  }) => (
    <div className={`SelectPlaces ${className}`}>
      <div className="SelectPlaces__wrapper">
        {Boolean(labelText) && (
          <label htmlFor={id} className="SelectPlaces__label">
            {labelText}{" "}
            {required && (
              <span className="SelectPlaces__label-required">*</span>
            )}
          </label>
        )}
        <input
          id={id}
          name={name}
          className={textFieldClasses(props)}
          {...getInputProps()}
          {...(placeholder && { placeholder })}
          value={inputValue}
          disabled={disabled}
        />
        {touched && errors && (
          <div className="SelectPlaces__input__errors__wrapper">
            <span className="SelectPlaces__input__errors">{errors}</span>
          </div>
        )}
      </div>
      {(loading || suggestions.length > 0) && (
        <div className="SelectPlaces__dropdown">
          {loading && <span>Loading...</span>}
          {suggestions.map((suggestion) => (
            <div
              {...getSuggestionItemProps(suggestion)}
              className="SelectPlaces__dropdown__suggestion"
            >
              <svg width="16px" height="16px" viewBox="0 0 16 16">
                <g
                  id="Asset-Artboard-Page"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Icons-/-Small-/-Shuffle-Icons-/-Small-/-Marker-/-To"
                    fill="#00B07D"
                    fillRule="nonzero"
                  >
                    <path
                      d="M8,0 C11.3137085,0 14,2.6862915 14,6 C14,8.06539641 12.2518015,11.1134503 8.75540444,15.1441615 C8.72443245,15.1798666 8.69096806,15.213331 8.65526296,15.244303 C8.23804771,15.6062114 7.60644319,15.5613763 7.24453479,15.1441611 L7.24453479,15.1441611 L6.94060198,14.7910317 C3.64686733,10.9349883 2,8.00464443 2,6 C2,2.6862915 4.6862915,0 8,0 Z M8,3 C6.34314575,3 5,4.34314575 5,6 C5,7.65685425 6.34314575,9 8,9 C9.65685425,9 11,7.65685425 11,6 C11,4.34314575 9.65685425,3 8,3 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </svg>
              <span className="SelectPlaces__dropdown__suggestion_text">
                {suggestion.description}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const handleChange = (value: string) => {
    setInputValue(value);
  };

  const handleSelect = (address: string) => {
    onChange(name, address);
    setInputValue(address);
  };

  return (
    <div className="SelectPlaces__wrapper" ref={ref}>
      <PlacesAutocomplete
        value={inputValue}
        onChange={handleChange}
        highlightFirstSuggestion={true}
        onSelect={handleSelect}
      >
        {renderFunc}
      </PlacesAutocomplete>
    </div>
  );
};

export default SelectPlaces;
