import socketIOClient from "socket.io-client";

export class SocketService {
  private static _instance: SocketService;
  public socket: SocketIOClient.Socket;

  private constructor() {
    this.socket = socketIOClient(`${process.env.REACT_APP_WS_URL}`, {
      transports: ["websocket"],
      jsonp: false,
      ...(process.env.NODE_ENV === "production" && {
        path: "/socket.io/",
        secure: true,
      }),
    });
  }

  public static getInstance(): SocketService {
    if (!SocketService._instance) {
      SocketService._instance = new SocketService();
    }

    return SocketService._instance;
  }
}
