export default class Logger {
  static info = (errorOrFunctionName: any, error?: any) => {
    if (process.env.NODE_ENV !== "production") {
      console.info(errorOrFunctionName, error);
    }
  }
  static error = (errorOrFunctionName: any, error?: any) => {
    if (process.env.NODE_ENV !== "production") {
      console.error(errorOrFunctionName, error);
    }
  }
}
