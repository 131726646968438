import React, { useState, useRef, useEffect } from "react";

import TextField from "../TextField";
import Tabs from "../Tabs";

import DownIcon from "../common/DownIcon";
import UpIcon from "../common/UpIcon";

import "./TimePicker.css";

export type ITimePicker = {
  id: string;
  name: string;
  onChange: (name: string, value: any) => void;
  value: any;
  className?: string;
  placeholder?: string;
};

const TimePicker: React.FC<ITimePicker> = ({
  id,
  onChange,
  name,
  value,
  ...rest
}) => {
  const [pick, setPick] = useState<any>("pick");
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e.preventDefault();

    setOpen(true);
  };

  const handleIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setOpen(!open);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  });

  const handleHoursChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newValue;
    let hour = event.target.value;

    if (isNaN(Number(hour))) {
      hour = "0";
    }

    if (Number(hour) > 12) {
      hour = "12";
    }

    if (pick === "pick") {
      newValue = {
        ...value,
        pickupTime: { ...value.pickupTime, hour },
      };
    } else {
      newValue = {
        ...value,
        dropoffTime: { ...value.dropoffTime, hour },
      };
    }

    onChange(name, newValue);
  };

  const handleMinutesChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newValue;
    let minute = event.target.value;

    if (isNaN(Number(minute))) {
      minute = "0";
    }

    if (Number(minute) > 60) {
      minute = "60";
    }

    if (pick === "pick") {
      newValue = {
        ...value,
        pickupTime: { ...value.pickupTime, minute },
      };
    } else {
      newValue = {
        ...value,
        dropoffTime: { ...value.dropoffTime, minute },
      };
    }

    onChange(name, newValue);
  };

  const handleAmPmChange = (_field: string, type: any) => {
    let newValue;

    if (pick === "pick") {
      newValue = { ...value, pickupTime: { ...value.pickupTime, type } };
    } else {
      newValue = {
        ...value,
        dropoffTime: { ...value.dropoffTime, type },
      };
    }
    onChange(name, newValue);
  };

  const handlePickChange = (_field: string, pick: any) => {
    setPick(pick);
  };

  return (
    <div className="TimePicker" ref={ref}>
      <TextField
        id={id}
        name={name}
        onFocus={handleFocus}
        onChange={() => ({})}
        value={`${value.pickupTime.hour}:${
          value.pickupTime.minute
        } ${value.pickupTime.type.toUpperCase()} - ${value.dropoffTime.hour}:${
          value.dropoffTime.minute
        } ${value.dropoffTime.type.toUpperCase()}`}
        rightIcon={open ? UpIcon : DownIcon}
        onIconClick={handleIconClick}
        placeholder={rest.placeholder}
      />
      {open && (
        <div className="TimePicker__wrapper">
          <div className="TimePicker__wrapper__inputs">
            <Tabs
              id={`${id}-pick`}
              name={`${name}-pick`}
              value={pick}
              onChange={handlePickChange}
              items={[
                {
                  label: "Depart at",
                  value: "pick",
                },
                {
                  label: "Arrive at",
                  value: "drop",
                },
              ]}
            />
          </div>
          <hr className="" />
          <div className="TimePicker__wrapper__inputs">
            <TextField
              id={`${id}-hour`}
              name={`${name}-hour`}
              onChange={handleHoursChange}
              value={
                pick === "pick" ? value.pickupTime.hour : value.dropoffTime.hour
              }
              className="TimePicker__wrapper__input"
              maxLength={2}
            />
            <span className="TimePicker__input_separator">:</span>
            <TextField
              id={`${id}-minute`}
              name={`${name}-minute`}
              onChange={handleMinutesChange}
              value={
                pick === "pick"
                  ? value.pickupTime.minute
                  : value.dropoffTime.minute
              }
              className="TimePicker__wrapper__input"
              maxLength={2}
            />
            <Tabs
              id={`${id}-type`}
              name={`${name}-type`}
              value={
                pick === "pick" ? value.pickupTime.type : value.dropoffTime.type
              }
              onChange={handleAmPmChange}
              items={[
                {
                  label: "AM",
                  value: "AM",
                },
                {
                  label: "PM",
                  value: "PM",
                },
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
