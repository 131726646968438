import React from "react";

const DownIcon: React.FC = () => (
  <svg width="17px" height="16px" viewBox="0 0 17 16" version="1.1">
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Icons-/-Eye-/-Disabled-Icons-/-Small-/-Arrow-/-Down"
        stroke="#00B07D"
        strokeWidth="2"
      >
        <polyline id="Path-3" points="4.25 6 8.5 10 12.75 6"></polyline>
      </g>
    </g>
  </svg>
);

export default DownIcon;
