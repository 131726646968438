import { createAction, createActionTypeScoper } from "../../utils/redux";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    LOAD_COMMUNITY: scopeActionType("LOAD_COMMUNITY"),
    LOAD_COMMUNITIES: scopeActionType("LOAD_COMMUNITIES"),
    LOAD_USER: scopeActionType("LOAD_USER"),
    LOAD_MEMBERS: scopeActionType("LOAD_MEMBERS"),
    LOAD_MEMBERS_SUCCESS: scopeActionType("LOAD_MEMBERS_SUCCESS"),
    REGISTER_COMMUNITY: scopeActionType("REGISTER_COMMUNITY"),
    REGISTER_COMMUNITY_SUCCESS: scopeActionType("REGISTER_COMMUNITY_SUCCESS"),
    REGISTER_COMMUNITY_ERROR: scopeActionType("REGISTER_COMMUNITY_ERROR"),
    LOAD_COMMUNITY_ASSETS: scopeActionType("LOAD_COMMUNITY_ASSETS"),
    LOAD_COMMUNITY_ASSETS_SUCCESS: scopeActionType("LOAD_COMMUNITY_ASSETS_SUCCESS"),
    SAVE_COMMUNITY_ASSET: scopeActionType("SAVE_COMMUNITY_ASSET"),
    ADD_COMMUNITY_MEMBERS: scopeActionType("ADD_COMMUNITY_MEMBERS"),
    ADD_COMMUNITY_MEMBERS_SUCCESS: scopeActionType("ADD_COMMUNITY_MEMBERS_SUCCESS"),
    ADD_COMMUNITY_MEMBERS_ERROR: scopeActionType("ADD_COMMUNITY_MEMBERS_ERROR"),
    LOAD_EDIT_MEMBER: scopeActionType("LOAD_EDIT_MEMBER"),
    EDIT_MEMBER: scopeActionType("EDIT_MEMBER"),
    DELETE_MEMBER: scopeActionType("DELETE_MEMBER"),
    EDIT_COMMUNITY: scopeActionType("EDIT_COMMUNITY"),
    EDIT_COMMUNITY_SUCCESS: scopeActionType("EDIT_COMMUNITY_SUCCESS"),
    EDIT_COMMUNITY_ERROR: scopeActionType("EDIT_COMMUNITY_ERROR"),
  };

  const actions = {
    loadCommunity: createAction(types.LOAD_COMMUNITY, (community: any) => ({
      community,
    })),
    loadCommunities: createAction(types.LOAD_COMMUNITIES, (communities: any) => ({
      communities,
    })),
    loadUser: createAction(types.LOAD_USER, (user: any) => ({
      user,
    })),
    loadMembers: createAction(types.LOAD_MEMBERS, (communityId: any) => ({
      communityId,
    })),
    loadMembersSuccess: createAction(types.LOAD_MEMBERS_SUCCESS, (members: any) => ({
      members,
    })),
    register: createAction(types.REGISTER_COMMUNITY, (community: any) => ({
      community,
    })),
    registerSuccess: createAction(types.REGISTER_COMMUNITY_SUCCESS),
    registerError: createAction(types.REGISTER_COMMUNITY_ERROR, (error: any) => ({
      error,
    })),
    loadAssets: createAction(types.LOAD_COMMUNITY_ASSETS, (data: any) => ({
      data,
    })),
    loadAssetsSuccess: createAction(types.LOAD_COMMUNITY_ASSETS_SUCCESS, (data: any) => ({
      data,
    })),
    saveAsset: createAction(types.SAVE_COMMUNITY_ASSET, (data: any) => ({
      data,
    })),
    addMembers: createAction(types.ADD_COMMUNITY_MEMBERS, (members: any) => ({
      members,
    })),
    addMembersSuccess: createAction(types.ADD_COMMUNITY_MEMBERS_SUCCESS),
    addMembersError: createAction(types.ADD_COMMUNITY_MEMBERS_ERROR, (error: any) => ({
      error,
    })),
    loadEditMember: createAction(types.LOAD_EDIT_MEMBER, (member: any) => ({
      member,
    })),
    editMember: createAction(types.EDIT_MEMBER, (member: any) => ({
      member,
    })),
    deleteMember: createAction(types.DELETE_MEMBER, (member: any) => ({
      member,
    })),
    editCommunity: createAction(types.EDIT_COMMUNITY, (data: any) => ({
      data,
    })),
    editCommunitySuccess: createAction(types.EDIT_COMMUNITY_SUCCESS),
    editCommunityError: createAction(types.EDIT_COMMUNITY_ERROR),
  };

  return { types, actions };
}
