import React from "react";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import classNames from "classnames";

import "./PhoneNumber.css";

export interface IPhoneNumber {
  id: string;
  name: string;
  value: any;
  label?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: any;
  touched?: boolean;
  errors?: any;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
}

export const phoneNumberClasses = (props: IPhoneNumber) =>
  classNames({
    "PhoneInputInput-valid":
      Boolean(props.touched) && props.errors === undefined,
    "PhoneInputInput-error": Boolean(props.touched) && props.errors,
  });

const PhoneNumber: React.FC<IPhoneNumber> = (props) => {
  const {
    id,
    name,
    label: labelText,
    disabled,
    onChange,
    value,
    placeholder,
    touched,
    errors,
    required,
  } = props;

  const handleChange = (val: string) => {
    onChange(name, val);
  };

  return (
    <div className="PhoneNumber__wrapper">
      {Boolean(labelText) && (
        <label htmlFor={id} className="PhoneInputInput__label">
          {labelText}{" "}
          {required && (
            <span className="PhoneInputInput__label-required">*</span>
          )}
        </label>
      )}
      <PhoneInput
        id={id}
        name={name}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        className={phoneNumberClasses(props)}
        placeholder={placeholder}
      />
      {touched && errors && (
        <div className="PhoneInputInput__errors__wrapper">
          <div className="PhoneInputInput__errors">{errors}</div>
        </div>
      )}
    </div>
  );
};

export default PhoneNumber;

export { isValidPhoneNumber };
