import React, { useState } from "react";

import RSelect, { Styles } from "react-select";

import classNames from "classnames";

import "./Select.css";

export interface ISelect {
  id: string;
  name: string;
  value: any;
  options: any[];
  label?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: any;
  touched?: boolean;
  errors?: any;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  styles?: Styles;
}

const Select: React.FC<ISelect> = (props) => {
  const {
    id,
    name,
    label: labelText,
    disabled,
    onChange,
    options,
    value,
    placeholder,
    required,
    touched,
    errors,
    styles,
  } = props;

  const searchOptionValue = (val: any) => {
    return options.find(
      (option: any) =>
        option.value === val ||
        (option.options &&
          option.options.find((subOption: any) => subOption.value === val)),
    );
  };

  const [ownValue, setOwnValue] = useState<any>(searchOptionValue(value));

  const handleChange = (selectedOption: any) => {
    onChange(name, selectedOption["value"]);
    setOwnValue(searchOptionValue(selectedOption));
  };

  const selectClasses = (props: ISelect) =>
    classNames({
      Select__wrapper: true,
      "Select__wrapper-valid":
        Boolean(props.touched) && props.errors === undefined,
      "Select__wrapper-error": Boolean(props.touched) && props.errors,
    });

  return (
    <div className={selectClasses(props)}>
      {Boolean(labelText) && (
        <label htmlFor={id} className="Select__label">
          {labelText}{" "}
          {required && <span className="Select__label-required">*</span>}
        </label>
      )}
      <RSelect
        id={id}
        name={name}
        isDisabled={disabled}
        value={ownValue}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        className="Select"
        classNamePrefix="Select"
        styles={styles}
      />
      {touched && errors && (
        <div className="Select__errors__wrapper">
          <span className="Select__errors">{errors}</span>
        </div>
      )}
    </div>
  );
};

export default Select;
