import React from "react";

import "./Tabs.css";
import Tab from "../Tab";

interface ITabs {
  id?: string;
  name: string;
  onChange: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => any;
  value: string;
  items: any;
}

const Tabs: React.FC<ITabs> = ({ items, value, name, onChange, ...rest }) => {
  const handleClick = (value: any) => {
    onChange(name, value);
  };

  return (
    <ul className="Tabs" {...rest}>
      {items.map((item: any) => (
        <Tab
          label={item.label}
          key={item.label}
          active={value === item.value}
          value={item.value}
          onClick={handleClick}
        />
      ))}
    </ul>
  );
};

export default Tabs;
