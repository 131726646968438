import { buildActions } from "./actions";
import { buildReducer } from "./reducer";
import { buildSaga } from "./saga";
import { buildSelectors } from "./selectors";

const scope = "CARPOOL";

const { actions, types } = buildActions(scope);
const reducer = buildReducer(types);
const selectors = buildSelectors(["carpool"]);
const saga = buildSaga(actions, types, selectors);

export { actions, reducer, saga, selectors };
