import React, { lazy } from "react";
import { Switch, useRouteMatch, Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import AuthenticatedRoute from "../AuthenticatedRoute";

const RidesHomePage = lazy(() => import("../../pages/rides/RidesHome"));
const CreateCarpoolPage = lazy(() => import("../../pages/rides/CreateCarpool"));

const RidesRoutes: React.FC = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <AuthenticatedRoute exact={true} path={path} component={RidesHomePage} />
      <AuthenticatedRoute
        path={`${path}/create`}
        component={CreateCarpoolPage}
      />
      <Route path="*">
        <Redirect to={path} />
      </Route>
    </Switch>
  );
};

export default RidesRoutes;
