import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { env } from './constants';

if (env.sentryUrl) {
  Sentry.init({
    dsn: env.sentryUrl,
    release: `${env.name}@${env.version}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.25,
    environment: env.environment,
  });
}
