import React from "react";

import "./Checkbox.css";

interface ICheckbox {
  name: string;
  id: string;
  value: any;
  label?: string;
  onChange?: any;
  onBlur?: (eventOrString: any) => void | ((e: any) => void);
  touched?: boolean;
  errors?: any;
}

const Checkbox: React.FC<ICheckbox> = ({
  children,
  value,
  name,
  id,
  touched,
  errors,
  onChange,
}) => {
  return (
    <>
      <label className="Checkbox">
        <div
          className={`Checkbox__input__wrapper ${
            Boolean(touched) && errors && `Checkbox__input__wrapper-error`
          }`}
        >
          <input
            type="checkbox"
            className="Checkbox__input"
            name={name}
            onChange={onChange}
            id={id}
            value={value}
          />
          <svg className="Checkbox__input__icon" viewBox="0 0 20 20">
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
          </svg>
        </div>
        <div className="Checkbox__label">{children}</div>
      </label>
      {touched && errors && (
        <div className="Checkbox__input__errors__wrapper">
          <span className="Checkbox__input__errors">{errors}</span>
        </div>
      )}
    </>
  );
};

export default Checkbox;
