import React, { useState, useRef, useEffect } from "react";

import classNames from "classnames";

export interface IDropdown {
  icon: any;
  title?: string;
  avatarBg?: boolean;
  avatarColor?: string;
  onHide?: () => void;
}

const Dropdown: React.FC<IDropdown> = ({
  icon: Icon,
  avatarBg = true,
  avatarColor,
  title,
  children,
  onHide,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | MouseEvent | HTMLDivElement>,
  ) => {
    e.preventDefault();

    setOpen(!open);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    return () => {
      if (open && onHide) {
        onHide();
      }
    };
  }, [onHide, open]);

  const getDropdownAvatarClassname = () =>
    classNames({
      "block h-8 w-8 mr-4 overflow-hidden": true,
      "rounded-full bg-secondary-green-bg": avatarBg === true,
    });

  return (
    <div className="relative focus:outline-none" ref={ref}>
      <button
        className="flex items-center cursor-pointer focus:outline-none"
        onClick={handleClick}
      >
        <div className={getDropdownAvatarClassname()}>
          <Icon
            className={`h-full w-full p-2 object-cover fill-current ${
              avatarColor ? `text-${avatarColor}` : ""
            }`}
          />
        </div>
        {title && (
          <>
            <span className="mr-2 text-gray-4 text-sm">{title}</span>
            <svg width="16px" height="16px" viewBox="0 0 16 16">
              <g
                id="Asset-Artboard-Page"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g
                  id="Icons-/-Small-/-Arrow-/-Down"
                  stroke="#00B07D"
                  strokeWidth="2"
                >
                  <polyline id="Path-3" points="4 6 8 10 12 6"></polyline>
                </g>
              </g>
            </svg>
          </>
        )}
      </button>
      {open && (
        <div
          className="absolute right-0 mt-2 p-8 w-56 bg-white rounded-lg shadow-xl z-50"
          onClick={handleClick}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
