import React from "react";

import classNames from "classnames";

import "./TextField.css";

import { ITextField } from "./TextFieldHook";
import { textFieldClasses } from "./TextFieldBase";

const buttonIconClasses = (props: any) =>
  classNames({
    "TextField__input__icon__wrapper flex items-center pr-3 rounded-tr rounded-br border-t border-b border-r border-gray-7 bg-white focus:outline-none": true,
    "TextField__input__icon__wrapper-error border-red-700":
      props.touched && props.errors,
  });

const Icon: React.FC<any> = (props) => {
  const { icon: Icon } = props;

  return (
    <span className={buttonIconClasses(props)}>
      <Icon className="TextField__input__icon fill-current text-primary-green" />
    </span>
  );
};

const ButtonIcon: React.FC<any> = (props) => {
  const { icon: Icon, onClick } = props;

  return (
    <button onClick={onClick} className={buttonIconClasses(props)}>
      <Icon className="TextField__input__icon fill-current text-primary-green" />
    </button>
  );
};

const TextFieldWithIcon: React.FC<ITextField> = (props) => {
  const {
    rightIcon: RightIcon,
    //leftIcon: LeftIcon,
    onIconClick,
    onFocus,
    onBlur,
    touched,
    errors,
    register,
    name,
    onChange,
    value,
    placeholder,
    type,
  } = props;

  return (
    <>
      <div className="flex w-full">
        <input
          name={name}
          autoComplete={name}
          ref={register}
          className={`${textFieldClasses(
            props,
          )} border-r-0 rounded-tr-none rounded-br-none`}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
        />
        {RightIcon && onIconClick ? (
          <ButtonIcon icon={RightIcon} onClick={onIconClick} {...props} />
        ) : (
          <Icon icon={RightIcon} {...props} />
        )}
      </div>
      {touched && errors && (
        <div className="TextField__input__errors__wrapper flex justify-end w-full">
          <span className="font-asemibold text-xs text-red-1">{errors}</span>
        </div>
      )}
    </>
  );
};

export default TextFieldWithIcon;
