import React from "react";

import classNames from "classnames";

import { ITextField } from "./TextField";
import { textFieldClasses } from "./TextFieldBase";

import "./TextField.css";

const buttonIconClasses = (props: any) =>
  classNames({
    TextField__input__icon__wrapper: true,
    "bg-gray-7 border-none text-gray-4": props.disabled,
    "TextField__input__icon__wrapper-error":
      Boolean(props.touched) && props.errors,
  });

const Icon: React.FC<any> = (props) => {
  const { icon: Icon } = props;

  return (
    <span className={buttonIconClasses(props)}>
      <Icon className="TextField__input__icon" />
    </span>
  );
};

const ButtonIcon: React.FC<any> = (props) => {
  const { icon: Icon, onClick, disabled } = props;

  return (
    <button
      onClick={onClick}
      className={buttonIconClasses(props)}
      disabled={disabled}
    >
      <Icon className="TextField__input__icon" />
    </button>
  );
};

const TextFieldWithIcon: React.FC<ITextField> = (props) => {
  const {
    rightIcon: RightIcon,
    //leftIcon: LeftIcon,
    onIconClick,
    onFocus,
    onBlur,
    touched,
    errors,
    name,
    value,
    onChange,
    placeholder,
    type,
    readOnly,
    disabled,
  } = props;

  return (
    <>
      <div className="TextField">
        <input
          name={name}
          autoComplete={name}
          className={`${textFieldClasses(
            props,
          )} TextField__input-no_border_right`}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          readOnly={readOnly}
          disabled={disabled}
        />
        {RightIcon && onIconClick ? (
          <ButtonIcon icon={RightIcon} onClick={onIconClick} {...props} />
        ) : (
          <Icon icon={RightIcon} {...props} />
        )}
      </div>
      {touched && errors && (
        <div className="TextField__input__errors__wrapper">
          <span className="TextField__input__errors">{errors}</span>
        </div>
      )}
    </>
  );
};

export default TextFieldWithIcon;
