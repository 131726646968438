import { selectorScoper } from "../../utils/redux";
import { IState } from "./types";

export function buildSelectors(path: string[]) {
  const scopeSelector = selectorScoper<IState>(path);

  const search = (state: any) => scopeSelector(state).search;
  const result = (state: any) => scopeSelector(state).result;
  const join = (state: any) => scopeSelector(state).join;
  const actives = (state: any) => scopeSelector(state).actives;
  const pending = (state: any) => scopeSelector(state).pending;
  const data = (state: any) => scopeSelector(state).data;
  const approveds = (state: any) => scopeSelector(state).approveds;
  const edit = (state: any) => scopeSelector(state).edit;
  const exceptions = (state: any) => scopeSelector(state).exceptions;
  const drivers = (state: any) => scopeSelector(state).drivers;
  const messages = (groupId: string) => (state: any) =>
    scopeSelector(state).messages[groupId] || [];
  const isLoading = (state: any) => scopeSelector(state).isLoading;

  return {
    search,
    result,
    join,
    actives,
    pending,
    data,
    approveds,
    edit,
    exceptions,
    drivers,
    messages,
    isLoading,
  };
}
