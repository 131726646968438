import React, { useState, useEffect, lazy, memo } from "react";

import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { AdminRoutes, ProfileRoutes, RidesRoutes } from "../routes";

import AuthenticatedRoute from "./AuthenticatedRoute";
import DefaultRoute from "./DefaultRoute";

import { selectors as appSelectors } from "../store/app";

// Auth pages
const EmailConfirmationPage = lazy(() =>
  import("../pages/auth/EmailConfirmation"),
);
const LoginPage = lazy(() => import("../pages/auth/Login"));
const RegisterPage = lazy(() => import("../pages/auth/Register"));
const RegisteredPage = lazy(() => import("../pages/auth/Registered"));
const FacebookPage = lazy(() => import("../pages/auth/Facebook"));
const CommunityUserPinPage = lazy(() =>
  import("../pages/auth/CommunityUserPinPage"),
);
const SetPasswordPage = lazy(() => import("../pages/auth/SetPassword"));

const HomePage = lazy(() => import("../pages/home"));
const LandingPage = lazy(() => import("../pages/landing"));
const TransportationModePage = lazy(() => import("../pages/transportationMode"));

// Profile pages
const SetupProfilePage = lazy(() => import("../pages/profile/SetupProfile"));

// Rides pages
const CreateCarpoolPage = lazy(() => import("../pages/rides/CreateCarpool"));
const JoinCarpoolPage = lazy(() => import("../pages/rides/JoinCarpool"));
const RequestsCarpoolPage = lazy(() =>
  import("../pages/rides/RequestsCarpool"),
);
const TerminateCarpoolPage = lazy(() =>
  import("../pages/rides/TerminateCarpool"),
);
const EditCarpoolPage = lazy(() => import("../pages/rides/EditCarpool"));
const ManageExceptionsCarpoolPage = lazy(() =>
  import("../pages/rides/ManageExceptions"),
);
const SeeExceptionsCarpoolPage = lazy(() =>
  import("../pages/rides/SeeExceptions"),
);
const RemoveCarpoolPage = lazy(() => import("../pages/rides/RemoveCarpool"));
const SearchPage = lazy(() => import("../pages/search"));
const AdminMemberEditPage = lazy(() => import("../pages/admin/editMember"));
const FaqPage = lazy(() => import("../pages/faq"));
const TermsAndUsePage = lazy(() => import("../pages/terms"));
const DisclaimerPage = lazy(() => import("../pages/disclaimer"));
const AboutPage = lazy(() => import("../pages/about"));
const CalculatorPage = lazy(() => import("../pages/calculator"));
const PrivacyAndPolicyPage = lazy(() => import("../pages/privacy"));

const Routes = memo(() => {
  const location: any = useLocation();
  const isAuthenticated = useSelector(appSelectors.isAuthenticated);
  const [previousLocation, setPreviousLocation] = useState(location);

  useEffect(() => {
    if (!(location.state && location.state.modal)) {
      setPreviousLocation(location);
    }
  }, [location]);

  const isModal =
    location.state && location.state.modal && previousLocation !== location;

  return (
    <>
      <Helmet titleTemplate="%s - QRyde v3.0" defaultTitle="QRyde v3.0">
        <meta name="description" content="QRyde application" />
      </Helmet>
      <Switch location={isModal ? previousLocation : location}>
        {isAuthenticated ? (
          <AuthenticatedRoute exact={true} path="/" component={HomePage} />
        ) : (
          <Route exact={true} path="/" component={LandingPage} />
        )}
        <DefaultRoute path="/search" component={SearchPage} />
        <DefaultRoute path="/faq" component={FaqPage} />
        <DefaultRoute path="/about" component={AboutPage} />
        <DefaultRoute path="/disclaimer" component={DisclaimerPage} />
        <DefaultRoute path="/terms-and-use" component={TermsAndUsePage} />
        <DefaultRoute path="/privacy-policy" component={PrivacyAndPolicyPage} />

        <DefaultRoute path="/calculator" component={CalculatorPage} />
        <Route
          path="/email_confirmation/:token"
          component={EmailConfirmationPage}
        />
        <Route
          path="/pin_confirmation/:communityId/:userId/:pin"
          component={CommunityUserPinPage}
        />
        <Route path="/auth/facebook/:token" component={FacebookPage} />
        <Route path="/login" component={LoginPage} />

        <AuthenticatedRoute path="/transportation-modes" component={TransportationModePage} />

        <Route path="/admin">
          <AdminRoutes />
        </Route>

        <Route path="/rides">
          <RidesRoutes />
        </Route>

        <Route path="/profile">
          <ProfileRoutes />
        </Route>

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>

      {/* Show the modal when a isModal is set */}
      {isModal && (
        <>
          <Route path="/sign-up" children={<RegisterPage />} />
          <Route path="/registered" children={<RegisteredPage />} />
          <Route path="/login" children={<LoginPage />} />
          <Route path="/:id/set-password" children={<SetPasswordPage />} />
          <AuthenticatedRoute path="/setup" children={<SetupProfilePage />} />
          <AuthenticatedRoute
            path="/rides/create"
            children={<CreateCarpoolPage />}
          />
          <AuthenticatedRoute
            path="/rides/join"
            children={<JoinCarpoolPage />}
          />
          <AuthenticatedRoute
            path="/rides/view"
            children={<RequestsCarpoolPage />}
          />
          <AuthenticatedRoute
            path="/rides/terminate/:so_id"
            children={<TerminateCarpoolPage />}
          />
          <AuthenticatedRoute
            path="/rides/edit/:so_id"
            children={<EditCarpoolPage />}
          />
          <AuthenticatedRoute
            path="/rides/manage/:group_id"
            children={<ManageExceptionsCarpoolPage />}
          />
          <AuthenticatedRoute
            path="/rides/exceptions/:group_id"
            children={<SeeExceptionsCarpoolPage />}
          />
          <AuthenticatedRoute
            path="/rides/remove/:so_id"
            children={<RemoveCarpoolPage />}
          />
          <AuthenticatedRoute
            path="/admin/member/edit"
            children={<AdminMemberEditPage />}
          />
        </>
      )}
    </>
  );
});

export default Routes;
