import React from "react";

import "./Switch.css";

export interface ISwitch {
  id: string;
  name: string;
  value: boolean;
  onChange: (name: string, value: boolean) => void;
}

const Switch: React.FC<ISwitch> = ({ id, name, value, onChange, children }) => {
  const handleClick = () => {
    onChange(name, !value);
  };

  const handleOnChange = () => {
    onChange(name, !value);
  };

  return (
    <div className="Switch" onClick={handleClick}>
      <input
        id={id}
        name={name}
        hidden={true}
        value={String(value)}
        onChange={handleOnChange}
      />

      <span
        className={`Switch__element transition-bg transition-all transition-250 ${
          Boolean(value) ? "Switch__element-active" : "Switch__element-inactive"
        }`}
      >
        <span
          className={`Switch__element__content ${
            Boolean(value)
              ? "Switch__element__content-active"
              : "Switch__element__content-inactive"
          }`}
        ></span>
      </span>
      <div className="Switch__label">{children}</div>
    </div>
  );
};

export default Switch;
