export const storage = {
  getItem: (key: string) => localStorage.getItem(key),
  setItem: (key: string, value: any) => {
    localStorage.setItem(key, value);
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  },
};

export type StorageService = typeof storage;
