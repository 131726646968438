import React from "react";

const NextIcon: React.FC = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16">
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Icons-/-Small-/-Shuffle-Icons-/-Small-/-Arrow-/-Right"
        stroke="#00B07D"
        strokeWidth="2"
      >
        <polyline
          id="Path-3"
          transform="translate(8.000000, 8.000000) rotate(-90.000000) translate(-8.000000, -8.000000) "
          points="4 6 8 10 12 6"
        ></polyline>
      </g>
    </g>
  </svg>
);

export default NextIcon;
