import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "@qryde/components-ui";

//import logoQryde from "../../../assets/svg/logo-qryde.svg";
import logoHIRideShare from "../../../assets/img/HiRideShare.png";
import { ReactComponent as InfoIcon } from "../../../assets/svg/info-icon.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/svg/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/svg/twitter-icon.svg";
import Loading from "../Loading";
import { actions as appActions, selectors as appSelectors } from "../../../store/app";

import "./LandingLayout.css";
import { ErrorBoundary } from "../ErrorBoundary";
import { ReactNode } from "react";

type LandingLayoutProps = {
  handleShowSearchForm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const LandingLayout: React.FC<LandingLayoutProps> = ({ children, handleShowSearchForm }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(appSelectors.isLoading);
  const showCovidAlert = useSelector(appSelectors.showCovidAlert);

  const covidActions = [
    {
      title: "Show details",
      onClick: () => {
        window.open("https://hawaiicovid19.com/");
      },
    },
    {
      title: "Close",
      onClick: () => {
        dispatch(appActions.toggleCovidAlert());
      },
    },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      {showCovidAlert && (
        <div className="border border-red-600">
          <Alert actions={covidActions} variant="error">
            <InfoIcon className="fill-current text-white w-6 h-6 inline-block mr-2" />
            Read about Hawaii Department of Transportation commitment to our customers during the
            COVID-19 crisis.
          </Alert>
        </div>
      )}
      {isLoading && <Loading />}
      <header className="py-3">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <a className="block" href="/">
              <img
                className="header_img_logo"
                src={logoHIRideShare}
                title="Hawaii Ride Share"
                alt="QRyde"
              />
            </a>
            <nav>
              <ul>
                <li className="inline-block mr-4">
                  <a href="/about" className="text-teal-600 text-lg">
                    about
                  </a>
                </li>
                <li className="inline-block relative dropdown">
                  <button
                    className="p-4 bg-teal-600 rounded-lg text-white text-lg focus:outline-none"
                    onClick={handleShowSearchForm}
                  >
                    find a ride
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <main className="flex-grow">
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
      <div className="flex">
        <Column title="HIRideShare in the News">
          <New
            title="Reclaim That Commute Time for Yourself"
            description=" "
            link="https://www.nytimes.com/interactive/2020/08/03/burst/reclaim-commute-time.html"
          />
          <New
            title="Honolulu, Hawaii Commuting – Best Places to Live"
            description=""
            link="https://www.bestplaces.net/transportation/city/hawaii/honolulu"
          />
          <New
            title="Commuting Oahu: ‘It Takes Your Time And It Gives You Back Stress’"
            description=" "
            link="https://www.civilbeat.org/2018/12/commuting-oahu-it-takes-your-time-and-it-gives-you-back-stress/"
          />
          <New title="HDOT Moves Podcast" description="" link="https://hidot.hawaii.gov/podcast/" />
        </Column>
        <Column title="What is HIRideShare?">
          <div className="text-center">
            <a
              className="text-center text-teal-600 items-center mr-6"
              href="/videos/QRE3CarpoolCreatingACarpool.mp4"
            >
              Carpool Create
            </a>
            <a
              className="text-center text-teal-600 items-center mr-6"
              href="/videos/QRE3CarpoolSearchForACarpool.mp4"
            >
              Search
            </a>
            <a
              className="text-center text-teal-600 items-center mr-6"
              href="/videos/QRE3CarpoolEditingACarpool.mp4"
            >
              Edit
            </a>
            <a
              className="text-center text-teal-600 items-center"
              href="/videos/QRE3CarpoolTransportationLog.mp4"
            >
              Logs
            </a>
          </div>
          <video title="What is HiRideShare?"
            width="90%"
            height="180"
             controls
             autoPlay={false}
             >
            <source src="/videos/QRE3CarpoolCreatingACarpool.mp4?feature=oembed" type="video/mp4"/>
          </video>
        </Column>
        <Column title="View HDOT's current highway projects in your community">
          <New
            title="HDOT Highways Program Status"
            description=""
            link="https://histategis.maps.arcgis.com/apps/MapSeries/index.html?appid=39e4d804242740a89d3fd0bc76d8d7de"
          />
          <New
            title="Go Akamai, Real Time Traffic Updates"
            description=""
            link="http://goakamai.org"
          />
          <New
            title="Statewide Road Closures"
            description=""
            link="https://hidot.hawaii.gov/highways/roadwork"
          />
        </Column>
        <Column title="State of Hawaii Transportation Options by County">
        <New
            title="City and County of Honolulu (Island of Oahu)."
            description=""
            link="https://www.honolulu.gov/transportation/home.html"
          />
          <New
            title="County of Maui (Islands of Maui, Molokai, and Lanai)."
            description=""
            link="https://www.mauicounty.gov/125/Department-of-Transportation"
          />
          <New
            title="County of Kauai (Island of Kauai)."
            description=""
            link="https://www.kauai.gov/Transportation"
          />
          <New
            title="County of Hawaii (Island of Hawaii also known as “Big Island”)."
            description=""
            link="http://heleonbus.org/"
          />
        </Column>
      </div>
      <footer className="footer flex p-8">
        <div className="flex-1">Copyright 2020 © Hawaii Car Sharing</div>
        <div className="flex-1 text-center">
          <FacebookIcon className="fill-current text-black w-6 h-6 inline-block mr-2" />
          <TwitterIcon className="fill-current text-black w-6 h-6 inline-block mr-2" />
        </div>
        <div className="flex-1 text-right">
          <a href="/faq">FAQ</a>
          <span className="mx-2">|</span>
          <a href="/calculator">Calculator</a>
          <span className="mx-2">|</span>
          <a href="/privacy-policy">Privacy Policy</a>
          <span className="mx-2">|</span>
          <a href="/disclaimer">Disclaimer</a>
          <span className="mx-2">|</span>
          <a href="/terms-and-use">{"Terms & Conditions"}</a>
        </div>
      </footer>
    </div>
  );
};

type ColumnProps = {
  title: string;
  children?: ReactNode;
};

const Column = React.memo<ColumnProps>(({ title, children }) => (
  <div className="flex-1">
    <h4 className="text-lg text-black-700 text-center font-bold">{title}</h4>
    <section className="px-4">{children}</section>
  </div>
));

type NewProps = {
  title: string;
  description: string;
  link?: string;
};

const New = React.memo<NewProps>(({ title, description, link }) => (
  <article className="my-2">
    {link ? (
      <a className="text-teal-500 font-bold" href={link}>
        {title}
      </a>
    ) : (
      <button className="text-teal-500 font-bold">{title}</button>
    )}
    <p className="text-sm font-hairline text-gray-600">{description}</p>
  </article>
));
