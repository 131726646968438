import React, { useState, useRef, useEffect } from "react";

import Calendar from "react-calendar/dist/entry.nostyle";
import TextField from "../TextField";
import { ITextField } from "../TextField/TextField";

import "./DatePicker.css";

import NextIcon from "../common/NextIcon";
import PrevIcon from "../common/PrevIcon";
import UpIcon from "../common/UpIcon";
import DownIcon from "../common/DownIcon";

export type IDatePicker = {
  handleChange: (field: string, value: any) => void;
  initialValue?: string;
} & Pick<
  ITextField,
  | "name"
  | "placeholder"
  | "touched"
  | "errors"
  | "required"
  | "disabled"
  | "className"
>;

const DatePickerHook: React.FC<IDatePicker> = ({
  name,
  handleChange,
  initialValue,
  placeholder,
  touched,
  errors,
  disabled,
  className,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<Date | Date[]>(
    initialValue ? new Date(initialValue) : new Date(),
  );
  const ref = useRef<HTMLDivElement>(null);

  const handleCalendarChange = (date: Date | Date[]) => {
    setDateValue(date);

    handleChange(name, date);
    setOpen(false);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  });

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e.preventDefault();

    setOpen(true);
  };

  const handleIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setOpen(!open);
  };

  return (
    <div className={`DatePicker w-full ${className}`} ref={ref}>
      <TextField
        id={name}
        name={name}
        value={(dateValue as Date).toLocaleDateString("fr-CA")}
        placeholder={placeholder}
        rightIcon={open ? UpIcon : DownIcon}
        onIconClick={handleIconClick}
        onFocus={handleFocus}
        touched={touched}
        errors={errors}
        disabled={disabled}
        readOnly={true}
      />
      {open && (
        <div className="DatePicker__wrapper">
          <Calendar
            showNeighboringMonth={false}
            nextLabel={<NextIcon />}
            next2Label={null}
            prevLabel={<PrevIcon />}
            prev2Label={null}
            value={dateValue}
            onChange={handleCalendarChange}
          />
        </div>
      )}
    </div>
  );
};

export default DatePickerHook;
