import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import { actions as appActions } from "../../../store/app";

class Notifier extends Component<any> {
  displayed: any = [];

  storeDisplayed = (id: any) => {
    this.displayed = [...this.displayed, id];
  };

  removeDisplayed = (id: any) => {
    this.displayed = this.displayed.filter((key: any) => id !== key);
  };

  componentDidUpdate() {
    const { snackbars = [] } = this.props;

    snackbars.forEach(
      ({ key, message, options = {}, dismissed = false }: any) => {
        if (dismissed) {
          this.props.closeSnackbar(key);
          return;
        }
        // Do nothing if snackbar is already displayed
        if (this.displayed.includes(key)) return;
        // Display snackbar using notistack
        this.props.enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event: any, reason: any, key: any) => {
            if (options.onClose) {
              options.onClose(event, reason, key);
            }
          },
          onExited: (_event: any, key: any) => {
            this.props.removeSnackbar(key);
            this.removeDisplayed(key);
          },
        });
        // Keep track of snackbars that we've displayed
        this.storeDisplayed(key);
      },
    );
  }

  render() {
    return null;
  }
}

const mapStateToProps = (store: any) => ({
  snackbars: store.app.snackbars,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ removeSnackbar: appActions.removeSnackbar }, dispatch);

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(Notifier),
);
