import React, { memo, HTMLAttributes } from "react";

interface IModal extends HTMLAttributes<HTMLDivElement> {
  image?: string;
  altImage?: string;
  onCloseClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Modal: React.FC<IModal> = memo(
  ({ children, image, onCloseClick, className, style }) => (
    <div className="fixed z-50 inset-0 overflow-auto bg-gray-1-opacity flex">
      <div
        className={`
          md:relative md:justify-center md:rounded md:h-auto md:shadow
          fixed shadow-inner max-w-4xl bottom-0
          inset-x-0 align-top m-auto justify-end
          bg-white w-full flex ${className}`}
        style={style}
      >
        {children}
        {image && (
          <div
            className="w-7/12"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        )}
        <div
          className="cursor-pointer rounded-full w-10 h-10 bg-white absolute top-0 right-0 mr-4 mt-8 flex items-center justify-center"
          onClick={onCloseClick}
        >
          <svg
            className="stroke-current text-primary-green h-4 w-4"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              strokeWidth="2"
              d="M8 8L1 1l7 7 7-7-7 7zm0 0l-7 7 7-7 7 7-7-7z"
            />
          </svg>
        </div>
      </div>
    </div>
  ),
);

Modal.defaultProps = {
  className: "",
};

export default Modal;
