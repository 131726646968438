import React, { memo } from "react";
import { Route, RouteProps } from "react-router";

import { DefaultLayout, UserLayout } from "../components/common/Layouts";
import { useSelector } from "react-redux";
import { selectors as appSelectors } from "../store/app";

interface IDefaultRoute {
  component: React.ComponentType<any>;
}

const DefaultRoute: React.FC<RouteProps & IDefaultRoute> = memo(
  ({ component: Component, ...rest }) => {
    const isAuthenticated = useSelector(appSelectors.isAuthenticated);

    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <UserLayout>
              <Component {...props} />
            </UserLayout>
          ) : (
            <DefaultLayout>
              <Component {...props} />
            </DefaultLayout>
          )
        }
      />
    );
  },
);

export default DefaultRoute;
