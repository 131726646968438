import { all, call, put, takeLatest, select } from "redux-saga/effects";

import { Actions, Selectors, Types } from "./types";
import { ApiService } from "../../services";
import { getErrorMessage } from "../../utils/errors";

import { actions as appActions } from "../app";
import { push, routerActions } from "connected-react-router";
import Logger from '../../utils/logger';

const api = ApiService.community;

export function buildSaga(actions: Actions, types: Types, selectors: Selectors) {
  function* registerSaga(action: ReturnType<Actions["register"]>) {
    try {
      yield put(appActions.loading());
      yield call(api.register, action.payload.community);
      yield put(actions.registerSuccess());
      yield put(appActions.loadingFinish());
      yield put(
        push("/registered", {
          modal: true,
        }),
      );
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(actions.registerError(getErrorMessage(error)));
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("registerSaga()", error);
    }
  }

  function* loadMembersSaga(action: ReturnType<Actions["loadMembers"]>) {
    try {
      yield put(appActions.loading());
      const { data } = yield call(api.getMembers, action.payload.communityId);

      yield put(actions.loadMembersSuccess(data));
      yield put(appActions.loadingFinish());
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("loadMembersSaga()", error);
    }
  }

  function* addMembersSaga(action: ReturnType<Actions["addMembers"]>) {
    try {
      yield put(appActions.loading());
      yield call(api.addMembers, action.payload.members);
      yield put(actions.addMembersSuccess());
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: "Members added",
          variant: "success",
        }),
      );
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      yield put(actions.addMembersError(getErrorMessage(error)));
      Logger.error("addMembersSaga()", error);
    }
  }

  function* loadAssetsSaga(action: ReturnType<Actions["loadAssets"]>) {
    try {
      yield put(appActions.loading());
      const { data } = yield call(api.getAssets, action.payload.data);

      yield put(actions.loadAssetsSuccess(data));
      yield put(appActions.loadingFinish());
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("loadAssetsSaga()", error);
    }
  }

  function* saveAssetsSaga(action: ReturnType<Actions["saveAsset"]>) {
    try {
      yield put(appActions.loading());
      yield call(api.saveAssets, action.payload.data);
      const { data } = yield call(api.getAssets, action.payload.data.communityId);
      yield put(actions.loadAssetsSuccess(data));
      yield put(
        appActions.enqueueSnackbar({
          message: "Asset Saved",
          variant: "success",
        }),
      );
      yield put(appActions.loadingFinish());
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("saveAssetsSaga()", error);
    }
  }

  function* editMemberSaga(action: ReturnType<Actions["editMember"]>) {
    try {
      yield put(appActions.loading());
      yield call(api.editMember, action.payload.member);
      yield put(
        appActions.enqueueSnackbar({
          message: "Member Edited",
          variant: "success",
        }),
      );
      yield put(appActions.loadingFinish());
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("editMemberSaga()", error);
    }
  }

  function* deleteMemberSaga(action: ReturnType<Actions["deleteMember"]>) {
    try {
      const community = yield select(selectors.community);

      yield put(appActions.loading());
      yield call(api.deleteMember, action.payload.member);

      yield put(actions.loadMembers(community.id));
      yield put(appActions.loadingFinish());
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("deleteMemberSaga()", error);
    }
  }

  function* editCommunitySaga(action: ReturnType<Actions["editCommunity"]>) {
    try {
      const community = yield select(selectors.community);

      yield put(appActions.loading());
      yield call(api.editCommunity, action.payload.data);

      yield put(
        actions.loadCommunity({
          ...community,
          ...action.payload.data,
        }),
      );
      yield put(actions.editCommunitySuccess());
      yield put(routerActions.push("/admin"));
      yield put(
        appActions.enqueueSnackbar({
          message: "Community Edited",
          variant: "success",
        }),
      );
      yield put(appActions.loadingFinish());
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      yield put(actions.editCommunityError());
      Logger.error("editCommunitySaga()", error);
    }
  }

  return function* mainSaga() {
    yield all([
      yield takeLatest(types.REGISTER_COMMUNITY, registerSaga),
      yield takeLatest(types.LOAD_MEMBERS, loadMembersSaga),
      yield takeLatest(types.ADD_COMMUNITY_MEMBERS, addMembersSaga),
      yield takeLatest(types.LOAD_COMMUNITY_ASSETS, loadAssetsSaga),
      yield takeLatest(types.SAVE_COMMUNITY_ASSET, saveAssetsSaga),
      yield takeLatest(types.EDIT_MEMBER, editMemberSaga),
      yield takeLatest(types.DELETE_MEMBER, deleteMemberSaga),
      yield takeLatest(types.EDIT_COMMUNITY, editCommunitySaga),
    ]);
  };
}
