import { createAction, createActionTypeScoper } from "../../utils/redux";

export function buildActions(scope: string) {
  const scopeActionType = createActionTypeScoper(scope);

  const types = {
    LOAD_TRANSPORTATION_MODE: scopeActionType("LOAD_TRANSPORTATION_MODE"),
    LOAD_TRANSPORTATION_MODE_SUCCESS: scopeActionType("LOAD_TRANSPORTATION_MODE_SUCCESS"),
    LOAD_TRANSPORTATION_MODE_ERROR: scopeActionType("LOAD_TRANSPORTATION_MODE_ERROR"),
    SAVE_TRANSPORTATION_MODE: scopeActionType("SAVE_TRANSPORTATION_MODE"),
    SAVE_TRANSPORTATION_MODE_SUCCESS: scopeActionType("SAVE_TRANSPORTATION_MODE_SUCCESS"),
    SAVE_TRANSPORTATION_MODE_ERROR: scopeActionType("SAVE_TRANSPORTATION_MODE_ERROR"),
    DELETE_TRANSPORTATION_MODE: scopeActionType("DELETE_TRANSPORTATION_MODE"),
    DELETE_TRANSPORTATION_MODE_SUCCESS: scopeActionType("DELETE_TRANSPORTATION_MODE_SUCCESS"),
    DELETE_TRANSPORTATION_MODE_ERROR: scopeActionType("DELETE_TRANSPORTATION_MODE_ERROR"),
  };

  const actions = {
    loadTransportationMode: createAction(
      types.LOAD_TRANSPORTATION_MODE,
      (transportationModeId: string) => ({ transportationModeId }),
    ),
    loadTransportationModeSuccess: createAction(
      types.LOAD_TRANSPORTATION_MODE_SUCCESS,
      (transportationModeList: any) => ({
        transportationModeList,
      }),
    ),
    loadTransportationModeError: createAction(
      types.LOAD_TRANSPORTATION_MODE_ERROR,
    ),
    saveTransportationMode: createAction(
      types.SAVE_TRANSPORTATION_MODE,
      (transportationMode: any) => ({
        transportationMode,
      }),
    ),
    saveTransportationModeSuccess: createAction(
      types.SAVE_TRANSPORTATION_MODE_SUCCESS,
      (transportationMode: any) => ({
        transportationMode,
      }),
    ),
    saveTransportationModeError: createAction(
      types.SAVE_TRANSPORTATION_MODE_ERROR,
    ),
    deleteTransportationMode: createAction(
      types.DELETE_TRANSPORTATION_MODE,
      (transportationMode: any) => ({
        transportationMode,
      }),
    ),
    deleteTransportationModeSuccess: createAction(
      types.DELETE_TRANSPORTATION_MODE_SUCCESS,
      (transportationMode: any) => ({
        transportationMode,
      }),
    ),
    deleteTransportationModeError: createAction(
      types.DELETE_TRANSPORTATION_MODE_ERROR,
    ),
  };

  return { types, actions };
}
