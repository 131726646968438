import { selectorScoper } from "../../utils/redux";
import { IState } from "./types";

export function buildSelectors(path: string[]) {
  const scopeSelector = selectorScoper<IState>(path);

  const list = (state: any) => scopeSelector(state).list;
  const isLoading = (state: any) => scopeSelector(state).isLoading;

  return {
    isLoading,
    list
  }
}
