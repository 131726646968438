import React, { useState, useRef, useEffect } from "react";

import TextField from "../TextField";
import Tabs from "../Tabs";

import DownIcon from "../common/DownIcon";
import UpIcon from "../common/UpIcon";

import "./TimePickerHook.css";

export type ITimePicker = {
  name: string;
  handleChange: (name: string, value: any) => void;
  initialValue?: typeof defaultValue;
  className?: string;
  placeholder?: string;
};

const defaultValue = {
  pickupTime: {
    hour: "09",
    minute: "00",
    type: "AM",
  },
  dropoffTime: {
    hour: "06",
    minute: "00",
    type: "PM",
  },
};

const TimePicker: React.FC<ITimePicker> = ({
  handleChange,
  name,
  initialValue,
  placeholder,
  className,
}) => {
  const [pick, setPick] = useState<any>("pick");
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState(initialValue ?? defaultValue);
  const ref = useRef<HTMLDivElement>(null);

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e.preventDefault();

    setOpen(true);
  };

  const handleIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setOpen(!open);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  });

  const handleHoursChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newValue;
    let hour = event.target.value;

    if (isNaN(Number(hour))) {
      hour = "0";
    }

    if (Number(hour) > 12) {
      hour = "12";
    }

    if (pick === "pick") {
      newValue = {
        ...value,
        pickupTime: { ...value.pickupTime, hour },
      };
    } else {
      newValue = {
        ...value,
        dropoffTime: { ...value.dropoffTime, hour },
      };
    }

    setValue(newValue);
    handleChange(name, newValue);
  };

  const handleMinutesChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newValue;
    let minute = event.target.value;

    if (isNaN(Number(minute))) {
      minute = "0";
    }

    if (Number(minute) > 60) {
      minute = "60";
    }

    if (pick === "pick") {
      newValue = {
        ...value,
        pickupTime: { ...value.pickupTime, minute },
      };
    } else {
      newValue = {
        ...value,
        dropoffTime: { ...value.dropoffTime, minute },
      };
    }

    setValue(newValue);
    handleChange(name, newValue);
  };

  const handleAmPmChange = (_field: string, type: any) => {
    let newValue;

    if (pick === "pick") {
      newValue = { ...value, pickupTime: { ...value.pickupTime, type } };
    } else {
      newValue = {
        ...value,
        dropoffTime: { ...value.dropoffTime, type },
      };
    }

    setValue(newValue);
    handleChange(name, newValue);
  };

  const handlePickChange = (_field: string, pick: any) => {
    setPick(pick);
  };

  return (
    <div className={`relative z-10 w-full ${className}`} ref={ref}>
      <TextField
        id={name}
        name={name}
        onFocus={handleFocus}
        value={`${value.pickupTime.hour}:${
          value.pickupTime.minute
        } ${value.pickupTime.type.toUpperCase()} - ${value.dropoffTime.hour}:${
          value.dropoffTime.minute
        } ${value.dropoffTime.type.toUpperCase()}`}
        rightIcon={open ? UpIcon : DownIcon}
        onIconClick={handleIconClick}
        placeholder={placeholder}
        onChange={() => ({})}
      />
      {open && (
        <div
          className="absolute left-0 my-4 bg-white rounded-lg"
          style={{ boxShadow: "0 2px 8px 0 rgba(24, 25, 27, 0.1)" }}
        >
          <div className="flex items-center p-4">
            <Tabs
              id={`${name}-pick`}
              name={`${name}-pick`}
              value={pick}
              onChange={handlePickChange}
              items={[
                {
                  label: "Depart at",
                  value: "pick",
                },
                {
                  label: "Arrive at",
                  value: "drop",
                },
              ]}
            />
          </div>
          <hr />
          <div className="flex items-center p-4">
            <TextField
              id={`${name}-hour`}
              name={`${name}-hour`}
              onChange={handleHoursChange}
              value={
                pick === "pick" ? value.pickupTime.hour : value.dropoffTime.hour
              }
              className="TimePicker__wrapper__input flex-1 p-0 text-center text-black"
              maxLength={2}
            />
            <span className="h-full flex-1">:</span>
            <TextField
              id={`${name}-minute`}
              name={`${name}-minute`}
              onChange={handleMinutesChange}
              value={
                pick === "pick"
                  ? value.pickupTime.minute
                  : value.dropoffTime.minute
              }
              className="TimePicker__wrapper__input flex-1 p-0 text-center text-black"
              maxLength={2}
            />
            <Tabs
              id={`${name}-type`}
              name={`${name}-type`}
              value={
                pick === "pick" ? value.pickupTime.type : value.dropoffTime.type
              }
              onChange={handleAmPmChange}
              items={[
                {
                  label: "AM",
                  value: "AM",
                },
                {
                  label: "PM",
                  value: "PM",
                },
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
