import React from "react";

import classNames from "classnames";

interface IAction {
  title: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface IAlert {
  message?: string;
  key?: string | number;
  actions?: IAction[];
  variant?: "info" | "warning" | "error" | "success";
}

const alertClassnames = (variant: IAlert["variant"]) =>
  classNames({
    "flex justify-between px-4 py-3 relative font-amedium text-sm": true,
    "text-white bg-blue-1": variant === "info",
    "text-white bg-yellow-1": variant === "warning",
    "text-white bg-red-1": variant === "error",
    "text-white bg-primary-green": variant === "success",
  });

const Alert: React.FC<IAlert> = ({
  message,
  children,
  variant = "info",
  actions,
  key,
}) => (
  <div className={alertClassnames(variant)} role="alert" key={key}>
    <span className="mr-8">{message ? message : children}</span>
    {actions && (
      <div className="cursor-pointer">
        {actions.map((action, index) => (
          <button
            className="font-asemibold uppercase font-semibold text-sm focus:outline-none ml-3"
            onClick={action.onClick}
            key={index}
          >
            {action.title}
          </button>
        ))}
      </div>
    )}
  </div>
);

export default Alert;
