import React, { InputHTMLAttributes } from "react";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import classNames from "classnames";

import "./PhoneNumberHook.css";

export interface IPhoneNumber extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  setValue: (name: string, value: string) => void;
  label?: string;
  touched?: boolean;
  errors?: any;
}

export const phoneNumberClasses = (props: IPhoneNumber) =>
  classNames({
    "PhoneInputInput-valid": props.touched && !props.errors,
    "PhoneInputInput-error": props.errors,
  });

const PhoneNumberHook: React.FC<IPhoneNumber> = (props) => {
  const {
    name,
    label: labelText,
    disabled,
    value,
    placeholder,
    //touched,
    errors,
    required,
    setValue,
  } = props;

  const handleChange = (val: string) => {
    setValue(name, val);
  };

  return (
    <div className="PhoneNumber__wrapper">
      {Boolean(labelText) && (
        <label htmlFor={name} className="PhoneInputInput__label">
          {labelText}{" "}
          {required && (
            <span className="PhoneInputInput__label-required">*</span>
          )}
        </label>
      )}
      <PhoneInput
        name={name}
        disabled={disabled}
        value={value}
        className={phoneNumberClasses(props)}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {errors && (
        <div className="PhoneInputInput__errors__wrapper">
          <div className="PhoneInputInput__errors">{errors}</div>
        </div>
      )}
    </div>
  );
};

export default PhoneNumberHook;

export { isValidPhoneNumber };
