import React, { lazy, memo } from "react";
import { Switch, useRouteMatch, Route, Redirect } from "react-router";

import AuthenticatedRoute from "../AuthenticatedRoute";

const DashboardAdminPage = lazy(() => import("../../pages/admin/dashboard"));
const AssetsAdminPage = lazy(() => import("../../pages/admin/assets"));
const MembersAdminPage = lazy(() => import("../../pages/admin/members"));
const AddMembersAdminPage = lazy(() => import("../../pages/admin/addMembers"));
const EditCommunityAdminPage = lazy(() => import("../../pages/admin/editCommunity"));

const AdminRoutes = memo(() => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <AuthenticatedRoute exact={true} path={path} component={DashboardAdminPage} />
      <AuthenticatedRoute path={`${path}/assets`} component={AssetsAdminPage} />
      <AuthenticatedRoute path={`${path}/members`} component={MembersAdminPage} />
      <AuthenticatedRoute path={`${path}/add-members`} component={AddMembersAdminPage} />
      <AuthenticatedRoute path={`${path}/edit-community`} component={EditCommunityAdminPage} />
      <Route path="*">
        <Redirect to={path} />
      </Route>
    </Switch>
  );
});

export default AdminRoutes;
