import { applyMiddleware, createStore as createReduxStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import { createWebsocketMiddleware } from "./middlewares";

import createRootReducer from "./reducers";
import sagas from "./sagas";

export const history = createBrowserHistory();

export const createStore = () => {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    createWebsocketMiddleware(),
  ];

  const store = createReduxStore(
    createRootReducer,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  // then run the saga
  sagaMiddleware.run(sagas);

  return store;
};

export default createStore;
