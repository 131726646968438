import React from "react";

const UpIcon: React.FC = () => (
  <svg width="17px" height="16px" viewBox="0 0 17 16">
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Icons-/-Eye-/-Disabled-Icons-/-Small-/-Arrow-/-Up"
        stroke="#00B07D"
        strokeWidth="2"
      >
        <polyline
          id="Path-3"
          transform="translate(8.500000, 8.000000) rotate(-180.000000) translate(-8.500000, -8.000000) "
          points="4.25 6 8.5 10 12.75 6"
        ></polyline>
      </g>
    </g>
  </svg>
);

export default UpIcon;
