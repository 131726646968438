import storage from "../utils/storage";

export class CarpoolService {
  public static async searchCarpool(data: any) {
    try {
      const token = await storage.getItem("token");
      let dataToSend: any = {
        homeAddress: data.homeAddress,
        workAddress: data.workAddress,
        daysOfWeek: data.daysOfWeek,
        pickupTime: `${data.time.pickupTime.hour}:${data.time.pickupTime.minute} ${data.time.pickupTime.type}`,
        dropoffTime: `${data.time.dropoffTime.hour}:${data.time.dropoffTime.minute} ${data.time.dropoffTime.type}`,
        otherCanDrive: data.otherCanDrive,
        nonSmokingCarpool: data.nonSmokingCarpool,
      };

      if (data.communityId) {
        dataToSend.communityId = data.communityId;
      }

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/search`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(dataToSend),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async createCarpool(data: object) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(`${process.env.REACT_APP_API_URL}/carpool`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async getActivesCarpool() {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/get_active`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async getDataCarpool(data: object) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/get_data`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async requestJoinCarpool(data: any) {
    try {
      const token = await storage.getItem("token");
      const dataToSend = {
        so_id: data.so_id,
        homeAddress: data.homeAddress,
        workAddress: data.workAddress,
        daysOfWeek: data.daysOfWeek,
        pickupTime: `${data.time.pickupTime.hour}:${data.time.pickupTime.minute} ${data.time.pickupTime.type}`,
        dropoffTime: `${data.time.dropoffTime.hour}:${data.time.dropoffTime.minute} ${data.time.dropoffTime.type}`,
        otherCanDrive: data.otherCanDrive,
        nonSmokingCarpool: data.nonSmokingCarpool,
        iWantToOptInAsDriver: data.iWantToOptInAsDriver,
        message: data.message,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/request_join`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(dataToSend),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async acceptRequestCarpool(data: object) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/accept`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async acceptDriveAndJoinRequestCarpool(data: object) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/accept_drive_join`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async rejectRequestCarpool(data: object) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/reject`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async getApprovedRequestsCarpool(so_id: string) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/get_approved_pool_data?so_id=${so_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async getChatMessageCarpool(group_id: string) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/messages?group_id=${group_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async terminateCarpool(data: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/terminate_pool`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async editCarpool(data: object) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/update_pool`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async getExceptionsCarpool(groupId: string) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/get_pool_exception?group_id=${groupId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async addExceptionCarpool(data: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/create_pool_exception`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async removeExceptionCarpool(data: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/delete_pool_exception`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async getAvailableDriverCarpool(data: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/get_available_driver_for_pool?so_id=${data.so_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async removeCarpool(data: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/remove_from_pool`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }

  public static async updateCarpoolRouteInfo(data: any) {
    try {
      const token = await storage.getItem("token");
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/carpool/update_carpool_route_info`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        },
      );

      const { result, ...rest } = await res.json();

      if (result === "success") {
        return rest;
      } else {
        throw rest;
      }
    } catch (error) {
      throw error;
    }
  }
}
