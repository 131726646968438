import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import React, { ReactNode } from "react";
import { Button } from "@qryde/components-ui";
import { useTranslation } from "react-i18next";

interface Props {
  children: ReactNode;
}

export function ErrorBoundary({ children }: Props) {
  return (
    <SentryErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <ErrorBoundaryDescription resetError={resetError} />
      )}
    >
      {children}
    </SentryErrorBoundary>
  );
}

interface ErrorBoundaryDescriptionProps {
  resetError: () => void;
}

function ErrorBoundaryDescription({ resetError }: ErrorBoundaryDescriptionProps) {
  const { t } = useTranslation();

  return (
    <section className="container">
      <div className="w-full mt-4">
        {t("component.errorBoundary.description")}
      </div>
      <div className="w-full">
        <Button onClick={resetError} className="mt-4">
          {t("component.errorBoundary.reset")}
        </Button>
      </div>
    </section>
  );
}
