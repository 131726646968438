import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";

import * as app from "./app";
import * as carpool from "./carpool";
import * as community from "./community";
import * as transportationMode from "./transportationMode";
import { history } from ".";

const createRootReducer = (history: History) =>
  combineReducers({
    app: app.reducer,
    carpool: carpool.reducer,
    community: community.reducer,
    router: connectRouter(history),
    transportationMode: transportationMode.reducer,
  });

const rootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "LOGOUT_SUCCESS") {
    state = undefined;
  }

  return createRootReducer(history)(state, action);
};

export default rootReducer;
