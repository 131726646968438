import React, { useState, useRef, useEffect } from "react";

import Calendar from "react-calendar/dist/entry.nostyle";
import TextField from "../TextField";
import { ITextField } from "../TextField/TextField";
import { format, parse, toDate } from "date-fns";

import "./DatePicker.css";

import NextIcon from "../common/NextIcon";
import PrevIcon from "../common/PrevIcon";
import UpIcon from "../common/UpIcon";
import DownIcon from "../common/DownIcon";

export type IDatePicker = {
  onChange: (field: string, value: any, shouldValidate?: boolean) => void;
  disabled?: boolean;
} & Pick<
  ITextField,
  "name" | "id" | "value" | "placeholder" | "touched" | "errors" | "required"
>;

const DatePicker: React.FC<IDatePicker> = ({
  name,
  id,
  onChange,
  value,
  placeholder,
  touched,
  errors,
  disabled,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<Date | Date[]>();
  const [inputDateValue, setInputDateValue] = useState<string>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const formattedValue = value
      ? typeof value === "string"
        ? toDate(parse(value, "yyyy-MM-dd", new Date()))
        : toDate(value)
      : new Date();

    setDateValue(formattedValue);
    setInputDateValue(format(formattedValue, "yyyy-MM-dd"));

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (date: Date | Date[]) => {
    setDateValue(date);
    setInputDateValue(format(date as Date, "yyyy-MM-dd"));

    onChange(name, date);
    setOpen(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    const dateValue = toDate(parse(value, "yyyy-MM-dd", new Date()));
    setInputDateValue(value);
    setDateValue(dateValue);
    onChange(name, dateValue);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setOpen(!open);
  };

  return (
    <div className="DatePicker w-full" ref={ref}>
      <TextField
        name={name}
        id={id}
        value={inputDateValue}
        placeholder={placeholder}
        rightIcon={open ? UpIcon : DownIcon}
        onIconClick={handleIconClick}
        touched={touched}
        errors={errors}
        disabled={disabled}
        type="date"
        onChange={handleInputChange}
      />
      {open && (
        <div className="DatePicker__wrapper">
          <Calendar
            showNeighboringMonth={false}
            nextLabel={<NextIcon />}
            next2Label={null}
            prevLabel={<PrevIcon />}
            prev2Label={null}
            value={dateValue}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
