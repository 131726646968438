import { selectorScoper } from "../../utils/redux";
import { IState } from "./types";

export function buildSelectors(path: string[]) {
  const scopeSelector = selectorScoper<IState>(path);

  const isLoading = (state: any) => scopeSelector(state).isLoading;
  const error = (state: any) => scopeSelector(state).error;
  const assets = (state: any) => scopeSelector(state).assets;
  const communities = (state: any) => scopeSelector(state).communities;
  const community = (state: any) => scopeSelector(state).community;
  const members = (state: any) => scopeSelector(state).members;
  const user = (state: any) => scopeSelector(state).user;
  const edit = (state: any) => scopeSelector(state).edit;

  return {
    isLoading,
    error,
    assets,
    communities,
    community,
    members,
    user,
    edit
  }
}
