import React from "react";

import Button from "../Button";
import Typography from "../Typography";

import "./Dialog.css";

interface IDialog {
  title: string;
  accept: string;
  onAcceptClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  cancel?: string;
  onCancelClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Dialog: React.FC<IDialog> = ({
  title,
  className,
  children,
  accept,
  onAcceptClick,
  cancel,
  onCancelClick,
}) => (
  <div className="Dialog">
    <div className={`Dialog__content ${className}`}>
      <div className="Dialog__content__title">
        <Typography variant="h3">{title}</Typography>
      </div>
      {children && <div className="Dialog__content__text">{children}</div>}
      <div className="Dialog__content__actions">
        {cancel && (
          <Button variant="white" onClick={onCancelClick!}>
            {cancel}
          </Button>
        )}
        <Button variant="primary" onClick={onAcceptClick}>
          {accept}
        </Button>
      </div>
    </div>
  </div>
);

export default Dialog;
