import React, { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import { createStore, history } from "./store";

import i18n from "./i18n";

import Routes from "./routes";
import Snackbar, { SNACKBAR_ANCHOR_ORIGIN } from "./components/common/Snackbar";
import Loading from "./components/common/Loading/Loading";

import 'antd/dist/antd.css';
import "./styles/index.css";

export const store = createStore();

const App: React.FC = () => {
  const snackBarContent = (key: string) => <Snackbar key={key} id={key} />;

  return (
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <ConnectedRouter history={history}>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={SNACKBAR_ANCHOR_ORIGIN}
            preventDuplicate={true}
            content={snackBarContent}
          >
            <I18nextProvider i18n={i18n}>
              <Routes />
            </I18nextProvider>
          </SnackbarProvider>
        </ConnectedRouter>
      </Suspense>
    </Provider>
  );
};

export default App;
