import { all, call, put, takeLatest } from "redux-saga/effects";
import { replace, push } from "connected-react-router";
import get from "lodash/get";

import { Actions, Selectors, Types } from "./types";
import { actions as carpoolActions } from "../carpool";
import { actions as communityActions } from "../community";
import { ApiService } from "../../services";
import storage from "../../utils/storage";
import { getErrorMessage } from "../../utils/errors";
import Logger from '../../utils/logger';

const api = new ApiService();

export function buildSaga(actions: Actions, types: Types, _selectors: Selectors) {
  function* login(token: string) {
    try {
      yield call(storage.setItem, "token", token);

      const { data: userData } = yield call(api.getUser);
      const { data: notifications } = yield call(api.getNotifications);
      const { data: incentives } = yield call(api.getIncentives, userData.id);

      // Load incentives
      yield put(actions.loadIncentives(incentives));

      yield put(actions.loginSuccess(userData));

      if (userData.homeAddress == null || userData.workAddress == null) {
        yield put(
          replace("/setup", {
            modal: true,
          }),
        );
      } else {
        yield put(replace("/"));

        // register the user address as initial search address
        yield put(
          carpoolActions.setSearchAddress({
            homeAddress: userData.homeAddress,
            workAddress: userData.workAddress,
          }),
        );
      }

      if (get(userData, "communities")) {
        const communities = get(userData, "communities");
        const actualCommunity = get(communities, "[0]");
        const actualCommunityUser = get(communities, "[0].CommunityUsers");

        yield put(communityActions.loadCommunities(communities));
        yield put(communityActions.loadCommunity(actualCommunity));
        yield put(communityActions.loadUser(actualCommunityUser));
      }

      yield put(actions.setNotifications(notifications));
      yield put(carpoolActions.getActivesCarpool());
      yield put(actions.loadingFinish());
    } catch (error) {
      throw error;
    }
  }

  function* bootstrap() {
    const token = yield call(storage.getItem, "token");

    if (token) {
      try {
        yield login(token);
      } catch (error) {
        yield put(actions.loadingFinish());
        yield call(storage.clear);
        Logger.info("bootstrapError()", error);
      }
    } else {
      const { data: incentives } = yield call(api.getIncentives, "ALL");

      yield put(actions.loadIncentives(incentives));
    }
  }

  function* setTokenSaga(action: ReturnType<Actions["setToken"]>) {
    try {
      yield call(storage.setItem, "token", action.payload.token);
      Logger.info("token", action.payload.token);
      yield bootstrap();
    } catch (error) {
      yield put(
        actions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("setTokenSaga error", error);
    }
  }

  function* loginSaga(action: ReturnType<Actions["login"]>) {
    try {
      yield put(actions.loading());
      const {
        data: { token },
      } = yield call(api.login, action.payload.userData);

      yield login(token);
    } catch (error) {
      yield put(actions.loadingFinish());
      yield put(actions.loginError(getErrorMessage(error)));
      yield put(
        actions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("loginSaga()", error);
    }
  }

  function* loginFacebookSaga(action: ReturnType<Actions["loginFacebook"]>) {
    try {
      yield put(actions.loading());
      const {
        data: { token },
      } = yield call(api.loginFacebook, action.payload.profile);

      yield login(token);
    } catch (error) {
      yield put(actions.loadingFinish());
      yield put(actions.loginError(getErrorMessage(error)));
      yield put(
        actions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("loginFacebookSaga()", error);
    }
  }

  function* loginGoogleSaga(action: ReturnType<Actions["loginGoogle"]>) {
    try {
      yield put(actions.loading());
      const {
        data: { token },
      } = yield call(api.loginGoogle, action.payload.profile);

      yield login(token);
    } catch (error) {
      yield put(actions.loadingFinish());
      yield put(actions.loginError(getErrorMessage(error)));
      yield put(
        actions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("loginGoogleSaga()", error);
    }
  }

  function* loginTwitterSaga() {
    try {
      yield put(actions.loading());
      const { data } = yield call(api.loginSuccess);

      if (data) {
        yield login(data.token);
      } else {
        yield put(actions.loadingFinish());
      }
    } catch (error) {
      yield put(actions.loadingFinish());
      Logger.error("loginTwitterSaga()", error);
    }
  }

  function* logoutSaga() {
    try {
      yield put(actions.loading());
      yield call(storage.clear);
      yield call(window.open, `${process.env.REACT_APP_API_URL}/users/logout`, "_self");
      // Get incentives
      const { data: incentives } = yield call(api.getIncentives, "ALL");

      yield put(actions.loadIncentives(incentives));

      yield put(replace("/"));

      yield put(actions.logoutSuccess());
      yield put(actions.loadingFinish());
    } catch (error) {
      yield put(actions.loadingFinish());
      yield put(actions.logoutError(getErrorMessage(error)));
      yield put(
        actions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("logoutSaga()", error);
    }
  }

  function* registerSaga(action: ReturnType<Actions["register"]>) {
    try {
      yield put(actions.loading());
      yield call(api.register, action.payload.userData);
      yield put(actions.registerSuccess());
      yield put(actions.loadingFinish());
      yield put(
        push("/registered", {
          modal: true,
        }),
      );
    } catch (error) {
      yield put(actions.loadingFinish());
      yield put(actions.registerError(getErrorMessage(error)));
      yield put(
        actions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("registerSaga()", error);
    }
  }

  function* confirmEmailSaga(action: ReturnType<Actions["confirmEmail"]>) {
    try {
      yield put(actions.loading());
      yield call(api.confirmEmail, action.payload.token);
      yield put(actions.loadingFinish());
      yield put(
        actions.enqueueSnackbar({
          message: "Account confirmed",
          variant: "success",
        }),
      );
      yield put(replace("/"));
    } catch (error) {
      yield put(actions.loadingFinish());
      yield put(replace("/"));
      yield put(
        actions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("confirmEmailSaga()", error);
    }
  }

  function* updateUserSaga(action: ReturnType<Actions["updateUser"]>) {
    try {
      yield put(actions.loading());
      yield call(api.updateUser, action.payload.userData);

      yield put(actions.enqueueSnackbar({ message: "Data updated", variant: "success" }));

      const res = yield call(api.getUser);

      const { data: userData } = res;

      // Update search data
      yield put(
        carpoolActions.setSearchAddress({
          homeAddress: userData.homeAddress,
          workAddress: userData.workAddress,
        }),
      );

      yield put(actions.loadingFinish());
      yield put(actions.loginSuccess(userData));
    } catch (error) {
      yield put(actions.loadingFinish());
      yield put(
        actions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("updateUserSaga()", error);
    }
  }

  function* markNotificationsAsReadSaga(action: ReturnType<Actions["markNotificationsAsRead"]>) {
    try {
      yield put(actions.loading());

      yield call(api.updateNotifications, action.payload.notification);

      const { data: notifications } = yield call(api.getNotifications);

      yield put(actions.setNotifications(notifications));
      yield put(actions.loadingFinish());
    } catch (error) {
      yield put(actions.loadingFinish());
      yield put(
        actions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("markNotificationsAsReadSaga()", error);
    }
  }

  function* validatePinSaga(action: ReturnType<Actions["validatePin"]>) {
    try {
      yield put(actions.loading());
      yield call(api.validatePin, action.payload.data);
      yield put(actions.loadingFinish());
      yield put(
        actions.enqueueSnackbar({
          message: "PIN Validated",
          variant: "success",
        }),
      );
      yield put(
        push(`/${action.payload.data.userId}/set-password`, {
          modal: true,
        }),
      );
    } catch (error) {
      yield put(actions.loadingFinish());
      yield put(
        actions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      yield put(push("/"));
      Logger.error("validatePinSaga()", error);
    }
  }

  function* setPasswordSaga(action: ReturnType<Actions["setPassword"]>) {
    try {
      yield put(actions.loading());
      yield call(api.setPassword, action.payload.data);
      yield put(actions.loadingFinish());
      yield put(
        actions.enqueueSnackbar({
          message: "Password setted",
          variant: "success",
        }),
      );
      yield put(push("/"));
    } catch (error) {
      yield put(actions.loadingFinish());
      yield put(
        actions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      yield put(push("/"));
      Logger.error("setPasswordSaga()", error);
    }
  }

  return function* mainSaga() {
    yield all([
      yield takeLatest(types.LOGIN, loginSaga),
      yield takeLatest(types.LOGIN_FACEBOOK, loginFacebookSaga),
      yield takeLatest(types.LOGIN_GOOGLE, loginGoogleSaga),
      yield takeLatest(types.LOGIN_TWITTER, loginTwitterSaga),
      yield takeLatest(types.SET_TOKEN, setTokenSaga),
      yield takeLatest(types.LOGOUT, logoutSaga),
      yield takeLatest(types.REGISTER, registerSaga),
      yield takeLatest(types.CONFIRM_EMAIL, confirmEmailSaga),
      yield takeLatest(types.UPDATE_USER, updateUserSaga),
      yield takeLatest(types.VALIDATE_PIN, validatePinSaga),
      yield takeLatest(types.SET_PASSWORD, setPasswordSaga),
      yield takeLatest(types.MARK_NOTIFICATIONS_AS_READ, markNotificationsAsReadSaga),
    ]);

    yield bootstrap();
  };
}
