import React from "react";

import classNames from "classnames";

import { ITextField } from "./TextFieldHook";

import "./TextField.css";

//TODO: Improve css classes

export const textFieldClasses = (props: ITextField) =>
  classNames({
    TextField__input: true,
    "TextField__input-valid": props.touched && !props.errors,
    "TextField__input-error": props.touched && props.errors,
  });

const TextFieldBase: React.FC<ITextField> = (props) => {
  const { touched, errors, label: labelText, register, name, ...rest } = props;

  return (
    <div
      className={`flex-col flex-1 ${Boolean(rest.className) && rest.className}`}
    >
      {labelText && (
        <label
          htmlFor={rest.id}
          className="uppercase font-asemibold text-gray-4 text-xs mb-2"
        >
          {labelText} {rest.required && <span className="text-red-2">*</span>}
        </label>
      )}
      <input
        {...rest}
        name={name}
        autoComplete={name}
        ref={register}
        className={textFieldClasses(props)}
        style={{ maxHeight: "52px" }}
      />
      {touched && errors && (
        <div className="flex justify-end w-full">
          <span className="font-asemibold text-xs text-red-1">{errors}</span>
        </div>
      )}
    </div>
  );
};

export default TextFieldBase;
