import React from "react";
import { Link } from "react-router-dom";
import { Button, Logo } from "@qryde/components-ui";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { Trans, withTranslation } from "react-i18next";

import LanguageMenu from "../LanguageMenu";

const Header: React.FC = () => {
  const dispatch = useDispatch();

  const handleSignUpButtonClick = () => {
    dispatch(
      push("/sign-up", {
        modal: true,
      }),
    );
  };

  const handleLoginButtonClick = () => {
    dispatch(
      push("/login", {
        modal: true,
      }),
    );
  };

  return (
    <header className="bg-dark-blue py-6 text-gray-4">
      <div className="container">
        <div className="flex justify-between items-center">
          <Link to="/">
            <Logo version="3.0" description="Rider" />
          </Link>
          <nav className="text-white flex items-center">
            <LanguageMenu />
            <Button
              className="mr-6"
              onClick={handleLoginButtonClick}
            >
              <Trans i18nKey="component.header.login">Login</Trans>
            </Button>
            <Button
              variant="primary"
              onClick={handleSignUpButtonClick}
            >
              <Trans i18nKey="component.header.sign_up">Sign up</Trans>
            </Button>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default withTranslation()(Header);
