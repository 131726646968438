import { handleActions } from "../../utils/redux";

import { IState, Types } from "./types";

const INITIAL_STATE: IState = {
  list: [],
  isLoading: false,
};

export function buildReducer(types: Types) {
  return handleActions<IState>(INITIAL_STATE, {
    [types.LOAD_TRANSPORTATION_MODE]: state => ({
      ...state,
      isLoading: true,
    }),
    [types.LOAD_TRANSPORTATION_MODE_SUCCESS]: (state, action) => ({
      ...state,
      list: action.payload.transportationModeList,
      isLoading: false,
    }),
    [types.LOAD_TRANSPORTATION_MODE_ERROR]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.SAVE_TRANSPORTATION_MODE]: state => ({
      ...state,
      isLoading: true,
    }),
    [types.SAVE_TRANSPORTATION_MODE_SUCCESS]: (state, action) => ({
      ...state,
      list: [...state.list, action.payload.transportationMode],
      isLoading: false,
    }),
    [types.SAVE_TRANSPORTATION_MODE_ERROR]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.DELETE_TRANSPORTATION_MODE]: state => ({
      ...state,
      isLoading: true,
    }),
    [types.DELETE_TRANSPORTATION_MODE_SUCCESS]: (state, action) => ({
      ...state,
      list: state.list.filter(
        (transportationMode: any) => transportationMode.id === action.payload.transportationMode.id,
      ),
      isLoading: false,
    }),
    [types.DELETE_TRANSPORTATION_MODE_ERROR]: (state, action) => ({
      ...state,
      isLoading: false,
    }),
  });
}
