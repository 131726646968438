import { handleActions } from "../../utils/redux";

import { IState, Types } from "./types";

const INITIAL_STATE: IState = {
  isLoading: false,
  error: null,
  community: null,
  communities: [],
  user: null,
  assets: [],
  members: [],
  edit: null,
};

export function buildReducer(types: Types) {
  return handleActions<IState>(INITIAL_STATE, {
    [types.LOAD_COMMUNITIES]: (state, action) => ({
      ...state,
      communities: action.payload.communities
    }),
    [types.LOAD_COMMUNITY]: (state, action) => ({
      ...state,
      community: action.payload.community
    }),
    [types.LOAD_USER]: (state, action) => ({
      ...state,
      user: action.payload.user
    }),
    [types.LOAD_MEMBERS_SUCCESS]: (state, action) => ({
      ...state,
      members: action.payload.members
    }),
    [types.REGISTER_COMMUNITY_ERROR]: (state, action) => ({
      ...state,
      error: action.payload.error,
    }),
    [types.LOAD_COMMUNITY_ASSETS_SUCCESS]: (state, action) => ({
      ...state,
      assets: action.payload.data,
    }),
    [types.LOAD_EDIT_MEMBER]: (state, action) => ({
      ...state,
      edit: action.payload.member,
    }),
    [types.EDIT_COMMUNITY]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [types.EDIT_COMMUNITY_SUCCESS]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [types.EDIT_COMMUNITY_ERROR]: (state) => ({
      ...state,
      isLoading: false,
    }),
  });
}
