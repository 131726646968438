import omit from "lodash/omit";

import { handleActions } from "../../utils/redux";
import { IState, Types } from "./types";

const INITIAL_STATE: IState = {
  isLoading: false,
  isAuthenticated: false,
  showCovidAlert: true,
  user: {
    homeAddress: "",
    workAddress: "",
  },
  login: {
    errors: null,
  },
  register: {
    errors: null,
  },
  update: {
    errors: null,
  },
  snackbars: [],
  notifications: [],
  incentives: {},
};

export function buildReducer(types: Types) {
  return handleActions<IState>(INITIAL_STATE, {
    [types.LOADING]: state => ({
      ...state,
      isLoading: true,
    }),
    [types.LOADING_FINISH]: state => ({
      ...state,
      isLoading: false,
    }),
    [types.LOGIN_SUCCESS]: (state, action) => {
      const user = omit(action.payload.user, ["communities"]);

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    },
    [types.LOGIN_ERROR]: (state, action) => ({
      ...state,
      login: {
        ...state.login,
        errors: action.payload.error,
      },
    }),
    [types.CLEAR_LOGIN_ERROR]: state => ({
      ...state,
      login: {
        ...state.login,
        errors: null,
      },
    }),
    [types.IS_AUTHENTICATED]: state => ({
      ...state,
      isAuthenticated: true,
    }),
    [types.LOGOUT_SUCCESS]: state => ({
      ...state,
      isAuthenticated: false,
      user: {
        homeAddress: "",
        workAddress: "",
      },
    }),
    [types.REGISTER_SUCCESS]: state => ({
      ...state,
      register: {
        ...state.register,
      },
    }),
    [types.REGISTER_ERROR]: (state, action) => ({
      ...state,
      register: {
        ...state.register,
        errors: action.payload.error,
      },
    }),
    [types.ENQUEUE_SNACKBAR]: (state, action) => ({
      ...state,
      snackbars: [
        ...state.snackbars,
        {
          ...action.payload.snackbar,
        },
      ],
    }),
    [types.CLOSE_SNACKBAR]: (state, action) => ({
      ...state,
      snackbars: state.snackbars.map(snackbar =>
        action.payload.dismissAll || snackbar.key === action.payload.key
          ? { ...snackbar, dismissed: true }
          : { ...snackbar },
      ),
    }),
    [types.REMOVE_SNACKBAR]: (state, action) => ({
      ...state,
      snackbars: state.snackbars.filter(snackbar => snackbar.key !== action.payload.key),
    }),
    [types.UPDATE_USER_ERROR]: (state, action) => ({
      ...state,
      update: {
        ...state.update,
        errors: action.payload.error,
      },
    }),
    [types.SET_NOTIFICATIONS]: (state, action) => ({
      ...state,
      notifications: action.payload.data,
    }),
    [types.MARK_NOTIFICATIONS_AS_READ]: (state, action) => ({
      ...state,
      notifications: state.notifications.map((notification: any) =>
        action.payload.notification.includes(notification.id)
          ? { ...notification, read: true }
          : notification,
      ),
    }),
    [types.LOAD_INCENTIVES]: (state, action) => ({
      ...state,
      incentives: action.payload.incentives,
    }),
    [types.TOGGLE_COVID_ALERT]: state => ({
      ...state,
      showCovidAlert: !state.showCovidAlert,
    }),

    // websocket reducers
    [types.ENQUEUE_NOTIFICATION]: (state, action) => ({
      ...state,
      notifications: [...state.notifications, action.payload.notification],
    }),

    [types.CLEAR_NOTIFICATION]: state => ({
      ...state,
      notifications: [],
    }),
  });
}
