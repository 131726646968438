import React, { useState, useRef, useEffect } from "react";

import Tab from "../Tab";

import TextField from "../TextField";

import DownIcon from "../common/DownIcon";
import UpIcon from "../common/UpIcon";

import "./DaysOfWeekSelect.css";

interface IDaysOfWeekSelect {
  id: string;
  name: string;
  onChange: any;
  placeholder?: string;
  value?: string;
}

const initialValue = ["mon", "tue", "wed", "thu", "fri"];

const DaysOfWeekSelect: React.FC<IDaysOfWeekSelect> = ({
  value,
  name,
  onChange,
  placeholder,
  ...rest
}) => {
  const [finalValue, setFinalValue] = useState<any>(value ?? initialValue);
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e.preventDefault();

    setOpen(true);
  };

  const handleIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setOpen(!open);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  });

  const items = [
    {
      label: "Mon",
      value: "mon",
    },
    {
      label: "Tue",
      value: "tue",
    },
    {
      label: "Wed",
      value: "wed",
    },
    {
      label: "Thu",
      value: "thu",
    },
    {
      label: "Fri",
      value: "fri",
    },
    {
      label: "Sat",
      value: "sat",
    },
    {
      label: "Sun",
      value: "sun",
    },
  ];

  const handleClick = (value: any) => {
    if (finalValue.includes(value)) {
      finalValue.splice(finalValue.indexOf(value), 1);
    } else {
      finalValue.push(value);
    }

    setFinalValue(finalValue);

    onChange(name, finalValue);
  };

  return (
    <div className="DaysOfWeekSelect" ref={ref}>
      <TextField
        id={"hour"}
        name={"hour"}
        onChange={() => ({})}
        onFocus={handleFocus}
        value={value}
        rightIcon={open ? UpIcon : DownIcon}
        onIconClick={handleIconClick}
        {...(placeholder && { placeholder })}
      />
      {open && (
        <div className="DaysOfWeekSelect__wrapper">
          <div className="DaysOfWeekSelect__wrapper__inputs">
            <ul className="Tabs DaysOfWeekSelect-Tabs" {...rest}>
              {items.map((item: any) => (
                <Tab
                  label={item.label}
                  key={item.label}
                  active={finalValue.includes(item.value)}
                  value={item.value}
                  onClick={handleClick}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DaysOfWeekSelect;
