import { all, call, put, takeLatest } from "redux-saga/effects";

import { Actions, Selectors, Types } from "./types";
import { ApiService } from "../../services";
import { getErrorMessage } from "../../utils/errors";

import { actions as appActions } from "../app";
import Logger from '../../utils/logger';

const api = ApiService.transportationMode;

export function buildSaga(actions: Actions, types: Types, _selectors: Selectors) {
  function* loadTransportationModeSaga(action: ReturnType<Actions["loadTransportationMode"]>) {
    try {
      const { data } = yield call(api.getAll, action.payload.transportationModeId);

      yield put(actions.loadTransportationModeSuccess(data));
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      yield put(actions.loadTransportationModeError());
      Logger.error("loadTransportationModeSaga()", error);
    }
  }

  function* saveTransportationModeSaga(action: ReturnType<Actions["saveTransportationMode"]>) {
    try {
      const { data } = yield call(api.save, action.payload.transportationMode);

      yield put(actions.saveTransportationModeSuccess(data));
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      yield put(actions.saveTransportationModeError());
      Logger.error("saveTransportationModeSaga()", error);
    }
  }

  function* deleteTransportationModeSaga(action: ReturnType<Actions["deleteTransportationMode"]>) {
    try {
      const { data } = yield call(api.delete, action.payload.transportationMode);

      yield put(actions.deleteTransportationModeSuccess(data));
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      yield put(actions.deleteTransportationModeError());
      Logger.error("deleteTransportationModeSaga()", error);
    }
  }

  return function* mainSaga() {
    yield all([
      yield takeLatest(types.LOAD_TRANSPORTATION_MODE, loadTransportationModeSaga),
      yield takeLatest(types.SAVE_TRANSPORTATION_MODE, saveTransportationModeSaga),
      yield takeLatest(types.DELETE_TRANSPORTATION_MODE, deleteTransportationModeSaga),
    ]);
  };
}
