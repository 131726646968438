import React, { InputHTMLAttributes } from "react";

import "./TextField.css";

import TextFieldWithIcon from "./TextFieldWithIcon";
import TextFieldBase from "./TextFieldBase";

export interface ITextField extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errors?: any;
  rightIcon?: React.FC;
  leftIcon?: React.FC;
  touched?: boolean;
  onIconClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  register?: any;
}

const TextField: React.FC<ITextField> = (props) => {
  const { type = "string", rightIcon, leftIcon } = props;

  if (rightIcon || leftIcon) {
    return <TextFieldWithIcon type={type} {...props} />;
  }

  return <TextFieldBase type={type} {...props} />;
};

export default TextField;
