import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { routerActions } from "connected-react-router";
import get from "lodash/get";
import { AnyAction } from "redux";

import { actions as appActions, selectors as appSelectors } from "../app";
import { ApiService } from "../../services";
import { getRouteInfo } from "../../utils/route";
import { getErrorMessage } from "../../utils/errors";
import { Actions, Selectors, Types } from "./types";
import storage from "../../utils/storage";
import Logger from '../../utils/logger';

const api = ApiService.carpool;

export function buildSaga(actions: Actions, types: Types, selectors: Selectors) {
  function* bootstrap() {
    try {
      // If exist token so the user is/was authenticated
      const token = yield call(storage.getItem, "token");

      if (token) {
        yield getActivesCarpoolSaga();
      }
    } catch (error) {
      yield put(appActions.loadingFinish());
      Logger.info("bootstrapError", error);
    }
  }

  function* searchCarpoolSaga(action: ReturnType<Actions["searchCarpool"]>) {
    try {
      yield put(appActions.loading());

      yield put(actions.setSearchAddress(action.payload.data));

      const { data: searchData } = yield call(api.searchCarpool, action.payload.data);

      yield put(actions.searchCarpoolSuccess(searchData));

      const isAuthenticated = yield select(appSelectors.isAuthenticated);
      const user = yield select(appSelectors.user);

      if (!searchData.length && isAuthenticated && user.ownsvehicle) {
        yield put(
          routerActions.push("/rides/create", {
            modal: true,
          }),
        );
      }

      yield put(appActions.loadingFinish());
    } catch (error) {
      yield put(actions.searchCarpoolError(getErrorMessage(error)));
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("searchCarpoolSaga()", error);
    }
  }

  function* createCarpoolSaga(action: ReturnType<Actions["createCarpool"]>) {
    try {
      yield put(appActions.loading());

      const { data: carpool } = yield call(api.createCarpool, action.payload.data);

      // Update carpool info for the first carpool

      const DirectionsService = new google.maps.DirectionsService();

      const { result: routeInfo } = yield new Promise<any>((resolve, reject) => {
        DirectionsService.route(
          {
            origin: action.payload.data.homeAddress,
            destination: action.payload.data.workAddress,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.IMPERIAL,
          },
          (result, status) => {
            if (status !== google.maps.DirectionsStatus.OK) {
              reject(result);
            }

            resolve({ status, result });
          },
        );
      });

      const total_miles = get(routeInfo, "routes[0].legs[0].distance.value", 1) / 1069;

      const routeInfoToSend = {
        group_id: carpool.group_id,
        total_miles: total_miles,
        route_info: [{ so_id: carpool.so_id, pu_stop: 1, do_stop: 2, distance: total_miles }],
      };

      yield call(api.updateCarpoolRouteInfo, routeInfoToSend);

      yield put(appActions.loadingFinish());

      yield put(
        appActions.enqueueSnackbar({
          message: "Carpool created",
          variant: "success",
        }),
      );
      yield put(actions.getActivesCarpool());
      yield put(routerActions.push("/rides"));
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(actions.createCarpoolError(getErrorMessage(error)));
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("createCarpoolSaga()", error);
    }
  }

  function* joinCarpoolSaga() {
    try {
      yield put(
        routerActions.push("/rides/join", {
          modal: true,
        }),
      );
    } catch (error) {
      Logger.error("joinCarpoolSaga()", error);
    }
  }

  function* requestJoinCarpoolSaga(action: ReturnType<Actions["requestJoinCarpool"]>) {
    try {
      yield put(appActions.loading());
      yield call(api.requestJoinCarpool, action.payload.data);

      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: "Carpool request sent",
          variant: "success",
        }),
      );
      yield put(actions.getActivesCarpool());

      yield put(routerActions.push("/rides"));
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      yield put(routerActions.push("/rides"));
      Logger.error("joinCarpoolSaga()", error);
    }
  }

  function* getDataCarpoolSaga(action: ReturnType<Actions["getDataCarpool"]>) {
    try {
      yield put(appActions.loading());
      const res = yield call(api.getDataCarpool, action.payload.carpool);

      const { data } = res;

      yield put(actions.getDataCarpoolSuccess(data));
      yield put(appActions.loadingFinish());

      yield put(
        routerActions.push("/rides/view", {
          modal: true,
        }),
      );
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      yield put(routerActions.push("/rides"));
      Logger.error("getDataCarpoolSaga()", error);
    }
  }

  function* getActivesCarpoolSaga() {
    try {
      yield put(appActions.loading());

      const { data: actives } = yield call(api.getActivesCarpool);

      const activesData = yield Promise.all(
        actives.map(async (active: any) => {
          const { data: dataActive } = await api.getDataCarpool({
            so_id: active.so_id,
          });

          const { data: approveds } = await api.getApprovedRequestsCarpool(active.so_id);

          const { data: exceptions } = await api.getExceptionsCarpool(active.group_id);

          return {
            ...active,
            requests: dataActive.requests.length,
            approveds,
            exceptions,
          };
        }),
      );

      yield put(actions.getActivesCarpoolSuccess(activesData));
      yield put(appActions.loadingFinish());
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("getActivesCarpoolSaga()", error);
    }
  }

  function* acceptRequestCarpoolSaga(action: ReturnType<Actions["acceptRequestCarpool"]>) {
    try {
      yield call(api.acceptRequestCarpool, action.payload.data);
      yield put(actions.acceptRequestCarpoolSuccess(action.payload.data));
      yield put(actions.getActivesCarpool());
      yield put(
        appActions.enqueueSnackbar({
          message: "Carpool request accepted",
          variant: "success",
        }),
      );

      // Update Carpool Info

      const actives = yield select(selectors.actives);

      const { data: standingOrders } = yield call(
        api.getApprovedRequestsCarpool,
        action.payload.data.so_id,
      );

      Logger.info("standingOrders", standingOrders);
      Logger.info("actives", actives);

      let {
        data: { so: carpoolData },
      } = yield call(api.getDataCarpool, action.payload.data);

      Logger.info("carpoolData", carpoolData);

      const standingOrderWalker = actives.find(
        (standingOrder: any) => standingOrder.group_id === carpoolData.group_id,
      );

      Logger.info("standingOrderWalker", standingOrderWalker);

      const updatedCarpoolRouteInfo = yield getRouteInfo(standingOrderWalker, standingOrders);

      yield call(api.updateCarpoolRouteInfo, updatedCarpoolRouteInfo);
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("acceptRequestCarpoolSaga()", error);
    }
  }

  function* acceptDriveAndJoinRequestCarpoolSaga(
    action: ReturnType<Actions["acceptJoinAndDriveRequestCarpool"]>,
  ) {
    try {
      yield call(api.acceptDriveAndJoinRequestCarpool, action.payload.data);
      yield put(actions.acceptRequestCarpoolSuccess(action.payload.data));
      yield put(actions.getActivesCarpool());
      yield put(
        appActions.enqueueSnackbar({
          message: "Carpool request accepted",
          variant: "success",
        }),
      );

      // Update Carpool Info

      const actives = yield select(selectors.actives);

      const { data: standingOrders } = yield call(
        api.getApprovedRequestsCarpool,
        action.payload.data.so_id,
      );

      Logger.info("standingOrders", standingOrders);
      Logger.info("actives", actives);

      let {
        data: { so: carpoolData },
      } = yield call(api.getDataCarpool, action.payload.data);

      Logger.info("carpoolData", carpoolData);

      const standingOrderWalker = actives.find(
        (standingOrder: any) => standingOrder.group_id === carpoolData.group_id,
      );

      Logger.info("standingOrderWalker", standingOrderWalker);

      const updatedCarpoolRouteInfo = yield getRouteInfo(standingOrderWalker, standingOrders);

      yield call(api.updateCarpoolRouteInfo, updatedCarpoolRouteInfo);
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("acceptDriveAndJoinRequestCarpoolSaga()", error);
    }
  }

  function* rejectRequestCarpoolSaga(action: ReturnType<Actions["rejectRequestCarpool"]>) {
    try {
      yield call(api.rejectRequestCarpool, action.payload.data);
      yield put(actions.rejectRequestCarpoolSuccess(action.payload.data));
      yield put(actions.getActivesCarpool());
      yield put(
        appActions.enqueueSnackbar({
          message: "Carpool request rejected",
          variant: "success",
        }),
      );
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      yield put(routerActions.push("/rides"));
      Logger.error("rejectRequestCarpoolSaga()", error);
    }
  }

  function* getApprovedRequestsCarpoolSaga(
    action: ReturnType<Actions["getApprovedRequestsCarpool"]>,
  ) {
    try {
      yield put(appActions.loading());

      const { data } = yield call(api.getApprovedRequestsCarpool, action.payload.carpool);

      yield put(actions.getApprovedRequestsCarpoolSuccess(data));
      yield put(appActions.loadingFinish());
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("getApprovedRequestsCarpoolSaga()", error);
    }
  }

  function* terminateCarpoolSaga(action: ReturnType<Actions["terminateCarpool"]>) {
    try {
      yield put(appActions.loading());

      const actives = yield select(selectors.actives);

      let {
        data: { so: carpoolData },
      } = yield call(api.getDataCarpool, action.payload.data);

      const standingOrderWalker = actives.find(
        (standingOrder: any) => standingOrder.group_id === carpoolData.group_id,
      );

      yield call(api.terminateCarpool, action.payload.data);

      // Update Carpool Info
      const { data: standingOrders } = yield call(
        api.getApprovedRequestsCarpool,
        action.payload.data.so_id,
      );

      const updatedCarpoolRouteInfo = yield getRouteInfo(standingOrderWalker, standingOrders);

      yield call(api.updateCarpoolRouteInfo, updatedCarpoolRouteInfo);

      yield put(actions.getActivesCarpool());

      yield put(appActions.loadingFinish());

      yield put(routerActions.push("/rides"));

      yield put(
        appActions.enqueueSnackbar({
          message: "Carpool terminated",
          variant: "success",
        }),
      );
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("terminateCarpoolSaga()", error);
    }
  }

  function* showEditCarpoolSaga(action: ReturnType<Actions["showEditCarpool"]>) {
    try {
      yield put(
        routerActions.push(`/rides/edit/${action.payload.data.so_id}`, {
          modal: true,
        }),
      );
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("showEditCarpoolSaga()", error);
    }
  }

  function* editCarpoolSaga(action: ReturnType<Actions["editCarpool"]>) {
    try {
      yield put(appActions.loading());

      yield call(api.editCarpool, action.payload.data);

      yield put(appActions.loadingFinish());

      yield put(
        appActions.enqueueSnackbar({
          message: "Carpool edited",
          variant: "success",
        }),
      );

      yield put(routerActions.push("/rides"));
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(actions.createCarpoolError(getErrorMessage(error)));
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("editCarpoolSaga()", error);
    }
  }

  function* getExceptionsCarpoolSaga(action: ReturnType<Actions["getExceptionsCarpool"]>) {
    try {
      yield put(appActions.loading());

      const { data } = yield call(api.getExceptionsCarpool, action.payload.data);

      yield put(appActions.loadingFinish());

      const exceptions = data.filter((exception: any) => {
        return (
          new Date(exception.start_date) > new Date() && new Date(exception.end_date) > new Date()
        );
      });

      yield put(actions.getExceptionsCarpoolSuccess(exceptions));
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("getExceptionsCarpoolSaga()", error);
    }
  }

  function* addExceptionCarpoolSaga(action: ReturnType<Actions["addExceptionCarpool"]>) {
    try {
      yield put(appActions.loading());

      const { data } = yield call(api.addExceptionCarpool, action.payload.data);

      yield put(appActions.loadingFinish());

      yield put(actions.addExceptionCarpoolSuccess(data));
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("addExceptionCarpoolSaga()", error);
    }
  }

  function* removeExceptionCarpoolSaga(action: ReturnType<Actions["removeExceptionCarpool"]>) {
    try {
      yield put(appActions.loading());

      yield call(api.removeExceptionCarpool, {
        so_id: action.payload.data.so_id,
        exceptionStartDate: action.payload.data.start_date,
        exceptionEndDate: action.payload.data.end_date,
        poolException: action.payload.data.exception,
      });

      yield put(actions.removeExceptionCarpoolSuccess(action.payload.data));

      yield put(appActions.loadingFinish());
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("removeExceptionCarpoolSaga()", error);
    }
  }

  function* getAvailableDriverCarpoolSaga(
    action: ReturnType<Actions["getAvailableDriverCarpool"]>,
  ) {
    try {
      yield put(appActions.loading());

      const { data } = yield call(api.getAvailableDriverCarpool, action.payload.data);

      yield put(actions.getAvailableDriverCarpoolSuccess(data));

      yield put(appActions.loadingFinish());
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("getAvailableDriverCarpoolSaga()", error);
    }
  }

  function* removeCarpoolSaga(action: ReturnType<Actions["removeCarpool"]>) {
    try {
      yield put(appActions.loading());

      yield call(api.removeCarpool, action.payload.data);

      yield put(actions.getActivesCarpool());

      yield put(appActions.loadingFinish());

      yield put(routerActions.push("/rides"));

      yield put(
        appActions.enqueueSnackbar({
          message: "Carpool removed",
          variant: "success",
        }),
      );
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("removeCarpoolSaga()", error);
    }
  }

  function* getCarpoolMessagesSaga(action: ReturnType<Actions["getCarpoolMessages"]>) {
    try {
      yield put(appActions.loading());

      const { data: messages } = yield call(api.getChatMessageCarpool, action.payload.data);

      yield put(
        actions.getCarpoolMessagesSuccess({
          group_id: action.payload.data,
          messages,
        }),
      );
    } catch (error) {
      yield put(appActions.loadingFinish());
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("getCarpoolMessagesSaga()", error);
    }
  }

  function* wsCarpoolUpdated(action: AnyAction) {
    try {
      const activesCarpool = yield select(selectors.actives);
      const pendingsCarpool = yield select(selectors.pending);

      let carpool = activesCarpool.find(
        (active: any) => active.group_id === action.payload.data.so.group_id,
      );

      if (!carpool) {
        carpool = pendingsCarpool.find(
          (pending: any) => pending.group_id === action.payload.data.so.group_id,
        );
      }

      if (carpool) {
        yield put(
          appActions.enqueueNotification({
            message: "The carpool that you are in was updated",
            type: "carpool_updated",
            read: false,
          }),
        );

        yield put(actions.getActivesCarpool());
      }
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("wsCarpoolUpdated()", error);
    }
  }

  function* wsCarpoolRequestJoin(action: AnyAction) {
    try {
      const user = yield select(appSelectors.user);
      if (get(action.payload, "data.creatorUser.id", false) === user.id) {
        yield put(
          appActions.enqueueNotification({
            message: "You have a new carpool request",
            type: "carpool_request_join",
            read: false,
          }),
        );

        yield put(actions.getActivesCarpool());
      }
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("wsCarpoolRequestJoin()", error);
    }
  }

  function* wsCarpoolRequestAccept(action: AnyAction) {
    try {
      const user = yield select(appSelectors.user);

      if (get(action.payload, "data.requesterUser.id", false) === user.id) {
        yield put(
          appActions.enqueueNotification({
            message: "Your carpool request was accepted",
            type: "carpool_request_accept",
            read: false,
          }),
        );

        yield put(actions.getActivesCarpool());
      }
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("wsCarpoolRequestAccept()", error);
    }
  }

  function* wsCarpoolRejectJoin(action: AnyAction) {
    try {
      const user = yield select(appSelectors.user);

      if (get(action.payload, "data.requesterUser.id", 0) === user.id) {
        yield put(
          appActions.enqueueNotification({
            message: "Your carpool request was rejected",
            type: "carpool_reject_join",
            read: false,
          }),
        );

        yield put(actions.getActivesCarpool());
      }
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("wsCarpoolRejectJoin()", error);
    }
  }

  function* wsCarpoolTerminated(action: AnyAction) {
    try {
      const activesCarpool = yield select(selectors.actives);

      const carpool = activesCarpool.find(
        (active: any) => active.group_id === action.payload.data.so.group_id,
      );

      if (carpool) {
        yield put(
          appActions.enqueueNotification({
            message: "Your carpool was terminated",
            type: "carpool_terminated",
            read: false,
          }),
        );

        yield put(actions.getActivesCarpool());
      }
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("wsCarpoolTerminated()", error);
    }
  }

  function* wsCarpoolRemovedUser(action: AnyAction) {
    try {
      const user = yield select(appSelectors.user);

      if (get(action.payload, "data.so.clientid", 0) === String(user.id)) {
        yield put(
          appActions.enqueueNotification({
            message: "You've been removed from carpool",
            type: "carpool_removed_user",
            read: false,
          }),
        );

        yield put(actions.getActivesCarpool());
      }
    } catch (error) {
      yield put(
        appActions.enqueueSnackbar({
          message: getErrorMessage(error),
          variant: "error",
        }),
      );
      Logger.error("wsCarpoolRemovedUser()", error);
    }
  }

  return function* mainSaga() {
    yield all([
      yield takeLatest(types.SEARCH_CARPOOL, searchCarpoolSaga),
      yield takeLatest(types.CREATE_CARPOOL, createCarpoolSaga),
      yield takeLatest(types.JOIN_CARPOOL, joinCarpoolSaga),
      yield takeLatest(types.REQUEST_JOIN_CARPOOL, requestJoinCarpoolSaga),
      yield takeLatest(types.GET_DATA_CARPOOL, getDataCarpoolSaga),
      yield takeLatest(types.GET_ACTIVES_CARPOOL, getActivesCarpoolSaga),
      yield takeLatest(types.GET_APPROVED_REQUESTS_CARPOOL, getApprovedRequestsCarpoolSaga),
      yield takeLatest(types.ACCEPT_REQUEST_CARPOOL, acceptRequestCarpoolSaga),
      yield takeLatest(
        types.ACCEPT_DRIVE_AND_JOIN_REQUEST_CARPOOL,
        acceptDriveAndJoinRequestCarpoolSaga,
      ),
      yield takeLatest(types.REJECT_REQUEST_CARPOOL, rejectRequestCarpoolSaga),
      yield takeLatest(types.SHOW_EDIT_CARPOOL, showEditCarpoolSaga),
      yield takeLatest(types.EDIT_CARPOOL, editCarpoolSaga),
      yield takeLatest(types.GET_EXCEPTIONS_CARPOOL, getExceptionsCarpoolSaga),
      yield takeLatest(types.ADD_EXCEPTION_CARPOOL, addExceptionCarpoolSaga),
      yield takeLatest(types.REMOVE_EXCEPTION_CARPOOL, removeExceptionCarpoolSaga),
      yield takeLatest(types.GET_AVAILABLE_DRIVER_CARPOOL, getAvailableDriverCarpoolSaga),
      yield takeLatest(types.TERMINATE_CARPOOL, terminateCarpoolSaga),
      yield takeLatest(types.REMOVE_CARPOOL, removeCarpoolSaga),
      yield takeLatest(types.GET_CARPOOL_MESSAGES, getCarpoolMessagesSaga),

      // Websockets
      yield takeLatest(types.WS_CARPOOL_UPDATED, wsCarpoolUpdated),
      yield takeLatest(types.WS_CARPOOL_REQUEST_JOIN, wsCarpoolRequestJoin),
      yield takeLatest(types.WS_CARPOOL_REQUEST_ACCEPT, wsCarpoolRequestAccept),
      yield takeLatest(types.WS_CARPOOL_REJECT_JOIN, wsCarpoolRejectJoin),
      yield takeLatest(types.WS_CARPOOL_TERMINATED, wsCarpoolTerminated),
      yield takeLatest(types.WS_CARPOOL_REMOVED_USER, wsCarpoolRemovedUser),
    ]);

    yield bootstrap();
  };
}
