import React, { lazy } from "react";
import { Switch, useRouteMatch, Route, Redirect } from "react-router";
import AuthenticatedRoute from "../AuthenticatedRoute";

const ProfileDetailsPage = lazy(() =>
  import("../../pages/profile/ProfileDetails"),
);
const ProfileEditPage = lazy(() => import("../../pages/profile/ProfileEdit"));
const ChangePasswordPage = lazy(() => import("../../pages/profile/ChangePassword"));

const ProfileRoutes: React.FC = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <AuthenticatedRoute
        exact={true}
        path={path}
        component={ProfileDetailsPage}
      />
      <AuthenticatedRoute path={`${path}/edit`} component={ProfileEditPage} />
      <AuthenticatedRoute path={`${path}/change-password`} component={ChangePasswordPage} />
      <Route path="*">
        <Redirect to={path} />
      </Route>
    </Switch>
  );
};

export default ProfileRoutes;
