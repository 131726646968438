import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { en } from "./locales";

const options = {
  interpolation: {
    escapeValue: false,
  },
  // debug: true,
  resources: {
    en: {
      common: en.en,
    },
  },
  fallbackLng: "en",
  ns: ["common"],
  defaultNS: "common",
  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default" as "default",
  },
};

i18n.use(LanguageDetector).init(options);

export default i18n;
